/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown4} from '../../content/dropdown/Dropdown4'
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../helpers'

//import {ID, Response} from '../../../../../../../_metronic/helpers'
import {
  User,
  UsersQueryResponse,
} from '../../../../app/modules/apps/user-management/users-list/core/_models'
//import {User, UsersQueryResponse} from './_models'
import {getAuth} from '../../../../app/modules/auth/core/AuthHelpers'
import {useNavigate} from 'react-router-dom'
//import {getAuth} from '../../../../auth/core/AuthHelpers'
const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
//const INVESTMENT_URL = `${API_URL}/admin/exchange_types`
//const GET_USERS_LIST_URL = `${API_URL}/admin/users_list`
const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

type Props = {
  className: string
}

const TablesWidget13: React.FC<Props> = ({className}) => {
  const statusColor: any = {
    submitted: 'primary',
    in_process: 'warning',
    approved: 'success',
    rejected: 'danger',
  }

  const [tableData, setTableData] = useState<any[]>([])

  const [triggerOne, setTriggerOne] = useState(true)

  const getInvestments = () => {
    axios
      .get(`${GET_INVESTMENT_URL}?status=submitted&page=1&item_per_page=100`, config)
      .then((response) => {
        // if (response.data.sessionExpired) {
        if (response.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';Max-Age=0;secure;path=/')
          })
          window.localStorage.clear()
          window.location.reload()
        }
        // }
        setTableData(response.data.data)
      })
  }
  // const setTableItem = (id: number, element: string, value: string) => {
  //   var tempTable = tableData
  //   const currentItem: any = tempTable[id - 1]
  //   currentItem[element] = value
  //   tempTable[id - 1] = currentItem
  //   // console.log(currentItem)
  //   setTableData(tempTable)
  //   setTriggerOne(!triggerOne)
  // }

  // const setTableItem = '1'

  // const formatDate = (date: Date) => {
  //   function pad(s: any) {
  //     return s < 10 ? '0' + s : s
  //   }
  //   var d = new Date(date)
  //   return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
  // }

  const navigate = useNavigate()

  useEffect(() => {
    getInvestments()
  }, [triggerOne])

  return (
    <div className={`card ${className}`}>
      <script></script>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Recently Submitted Transactions </span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {
              tableData.filter(
                (item) => item.status === 'submitted' || item.status === 'in_process'
              ).length
            }{' '}
            pending requests
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 overflow-auto' style={{maxHeight: '40rem'}}>
        {/* begin::Table container */}
        <div className='table-responsive' style={{maxHeight: '40rem'}}>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 table-sticky-header'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}
                <th className='w-25px'>Sr. No.</th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-130px'>UCC</th>
                <th className='min-w-150px'>Scheme</th>
                <th className='min-w-120px'>Amount (₹)</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-120px'>Time</th>
                <th className='min-w-120px'>NAV (₹)</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-60px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='overflow-auto'>
              {tableData
                .filter((item) => item.status === 'submitted' || item.status === 'in_process')
                .sort((a: any, b: any) => (a.startDate > b.startDate ? -1 : 1))
                .map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className='text-dark fw-bolder text-center fs-6'>{key + 1}</div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item.firstName} {item.lastName}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item.ucc}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item.schemeName}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {item.productType}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item.investmentAmount}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        Lock-in:{' '}
                        {
                          //item.lock_in_period
                        }
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(`${item.startDate}`).toLocaleDateString('en-GB')}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(`${item.startDate}`).toLocaleTimeString('en-IN', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </a>
                    </td>
                    <td className='text-dark fw-bolder text-center text-hover-primary fs-6'>
                      {item.currentNavRate || '-'}
                    </td>
                    <td id={`row` + String(item.id)}>
                      <span
                        className={`badge badge-light-${
                          statusColor[item.status ? item.status : 'primary']
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>
                    <td className='text-end'>
                      {/* begin::Menu */}
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onMouseOver={() => navigate('#')}
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </button>
                      <Dropdown4
                        item={item}
                        triggerOne={triggerOne}
                        setTriggerOne={setTriggerOne}
                      />
                      {/* end::Menu */}
                      {/* <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a> */}
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget13}
