import React from 'react'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/reports',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Reports() {
  return (
    <div>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Mutual Fund</PageTitle>
      <div className='container'>
        <div className='row my-2'>
          <div className='col-sm-6 d-flex align-items-stretch'>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>Current Holdings</h5>
                <p className='card-text my-5 fs-6'>
                  Know your current mutual fund investments ('Valuation Report') with multiple
                  report formats.
                </p>
                <Link to={'/reports/current-holdings'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div>

          <div className='col-sm-6 d-flex align-items-stretch'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>Transactions</h5>
                <p className='card-text my-5 fs-6'>
                  View historical transactions, dividend history and access Exchange Transaction
                  Confirmation Statement.
                </p>
                <Link to={'/reports/transactions'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div>
          {/* <div className='col-sm-4 d-flex align-items-stretch'>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>SIP Status Report</h5>
                <p className='card-text my-5 fs-6'>
                  Get the status of your mutual fund systematic plans - SIP/STP/SWP.
                </p>
                <Link to={'/reports/systematic-status'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className='row my-5'>
          <div className='col-sm-4'>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>Profit & Loss</h5>
                <p className='card-text my-5 fs-6'>Get the Profit & Loss on your investments.</p>
                <Link to={'/reports/profit-loss'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>ELSS Status</h5>
                <p className='card-text my-5 fs-6'>Get insights on your ELSS investments.</p>
                <Link to={'/reports/elss'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title text-primary fw-bold fs-3'>
                  SIP Plans Performance
                </h5>
                <p className='card-text my-5 fs-6'>Get performance of your systematic plans.</p>
                <Link to={'/reports/systematic-performance'} className='btn btn-primary me-0'>
                  View Report
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {Reports}
