import React, {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {PageTitle} from '../../../_metronic/layout/core'

const Disclaimer: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Disclaimer</PageTitle>
      <div className='content flex-column-fluid' id='kt_content'>
        <div className='card'>
          <div className='card-body p-lg-17'>
            <div className='row mb-3 d-flex justify-content-evenly'>
              <div className='col-md-12 pe-lg-10'>
                <h2 className='text-center mb-6'>DISCLAIMER</h2>
                <p style={{textAlign: "justify"}}>
                  By accessing this website and/or App or any of its associate/group sites
                  ("Website"), you have read, understood and agree to be legally bound by the terms
                  of the following disclaimer and user agreement.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa Kendra Private Limited ("MF Sewa") is engaged in providing financial and
                  related services in India. MF Sewa and its sub-distributors known as MF Sewa
                  Wealth Partners are mutual fund distributors registered with AMFI. In accordance
                  with the applicable laws, MF Sewa and MF Sewa Wealth Partners are permitted to
                  only render incidental advice with respect to mutual fund products only to its
                  mutual fund distribution client. For every other purpose, including distribution
                  of non-mutual fund products, all material displayed on the Website is for
                  informational purposes only. Further, it is not intended as investment advice or
                  an opinion concerning securities or a public offer proving a basis for an
                  investment decision.
                </p>
                <p style={{textAlign: "justify"}}>
                  No material /information contained on the Website constitutes or shall be
                  interpreted as investment advice, offer, solicitation or advertisement with
                  respect to the purchase or sale of or to deal with any security and no part of it
                  shall form the basis of or be relied upon in connection with any contract or
                  commitment whatsoever.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa has taken due care and caution in the compilation of the data and the
                  contents for its Website and Online Desks. However, MF Sewa does not guarantee the
                  suitability, accuracy, adequacy or completeness of any information and is not
                  responsible for any errors or omissions or for the results obtained from the use
                  of such information. MF Sewa specifically states that it cannot be held liable for
                  any damages (monetary, legal or otherwise) caused by any error, omission,
                  interruption, deletion, defect, failure, and that it has no financial liability
                  whatsoever to any user on account of the use of the information provided on its
                  website. MF Sewa does not provide any warranties (whether expressed or implied),
                  as to the quality, accuracy, efficacy, completeness, performance, fitness or any
                  of the contents of the Website, including (but not limited) to any comments,
                  feedback and advertisements contained within the Website.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa strongly recommends its users to verify the veracity /appropriateness of
                  the information before making any decisions. The information on this Website is
                  updated from time to time. MF Sewa is also not responsible for any errors,
                  omissions or representations on any of its pages or on any links on any of its
                  pages. MF Sewa does not endorse in any way any advertisers on its web pages. This
                  Website contains articles contributed by individuals. The views are exclusively
                  their own and do not necessarily represent the views of the Website owners or its
                  management. The linked sites are not under MF Sewa’s control and MF Sewa is not
                  responsible for the contents of any linked site or any link contained in a linked
                  site, or any changes or updates to such sites. MF Sewa is providing these links to
                  you only as a convenience, and the inclusion of any link does not imply
                  endorsement by MF Sewa or the Website.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa hereby expressly disclaims any implied warranties imputed by the laws of
                  any jurisdiction. MF Sewa considers itself and intends to be governed by the
                  exclusive jurisdiction of the courts of Chandigarh in India. If you don't agree
                  with any of the disclaimers above, please do not read the material on any of the
                  pages and exit the Website immediately. This Website is specifically for users in
                  the territory of India. Although access to users outside India is not denied, MF
                  Sewa shall have no legal liabilities whatsoever in any laws of any jurisdiction
                  other than India. MF Sewa reserves the right to make changes to its website,
                  including but not limited to the Terms of Use, Disclaimers and Privacy Policy
                  contained herein.
                </p>
                <p style={{textAlign: "justify"}}>
                  Past Performance Is Not Indicative of Future Returns. Mutual Fund investments are
                  subject to market risks. Read all Scheme related documents carefully before
                  investing.
                </p>

                <div className='d-flex w-100 justify-content-around align-items-center my-4'>
                  <span>
                    {' '}
                    <a href='https://www.sebi.gov.in/' target={'_blank'}> SEBI </a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.rbi.org.in/' target={'_blank'}> RBI </a>
                  </span>
                  <span>
                    {' '}
                    <a href='http://www.bseindia.com/' target={'_blank'}> BSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nseindia.com/' target={'_blank'}> NSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.cdslindia.com/' target={'_blank'}> CDSL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nsdl.co.in/' target={'_blank'}> NSDL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.amfiindia.com/' target={'_blank'}> AMFI</a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.icexindia.com/' target={'_blank'}> ICEX</a>{' '}
                  </span>
                </div>

                <h5 className='mt-7 mb-4'>Attention Investors:</h5>
                <ol>
                  <li style={{textAlign: "justify"}}>
                    "Prevent Unauthorized transactions in your Trading/Demat Account. Update your
                    mobile numbers/email IDs with your Stock Brokers/Depository Participant. Receive
                    alerts/information of your transaction/all debit and other important
                    transactions in your Trading/ Demat Account directly from Exchange/NSDL/CDSL at
                    the end of the day. Issued in the interest of investors."
                  </li>
                  <li style={{textAlign: "justify"}}>
                    "KYC is one time exercise while dealing in securities markets - once KYC is done
                    through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need
                    not undergo the same process again when you approach another intermediary."
                  </li>
                  <li style={{textAlign: "justify"}}>
                    "No need to issue cheques by investors while subscribing to IPO. Just write the
                    bank account number and sign in the application form to authorize your bank to
                    make payment in case of allotment. No worries for refund as the money remains in
                    investor's account.".
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Check your Securities /MF/ Bonds in the consolidated account statement issued by
                    NSDL/CDSL every month.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Mutual Fund investments are subject to market risk, read all scheme related
                    documents carefully.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    In case of any grievances against any listed company, member of stock exchanges
                    and Depository Participant, please lodge the same through online mechanisms more
                    specifically through SEBI SCORES portal and SEBI SCORES mobile application for
                    effective redressal of grievances. Furthermore, you can also lodge online
                    complaints / grievances directly with NSDL/CDSL/depository participants through
                    the link / option available on their website.
                  </li>
                </ol>
              </div>
              {/* <div className='col-md-6 position-relative ps-2'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
                  className='w-75 position-absolute top-50 start-50 translate-middle .img-fluid'
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Disclaimer}
