import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../user-management/users-list/core/_models'
import {getAuth} from '../../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../auth/core/Auth'
import {ToastifyWidget} from '../../../../../_metronic/partials/widgets'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/get_customer_details`
const GET_VALUE_REPORT = `${API_URL}/customer/get_overall_report`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {customerId, asOnDate, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const [userReportData, setUserReportData] = useState<any>()
  const [userReportMeta, setUserReportMeta] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()

  // convert filters to string to pass in url
  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  //console.log('filters:', applyFilters)

  const getCustomerDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}/${customerId}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getUserReport = (customerId: any, asOnDate: String): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_VALUE_REPORT}?userId=${customerId}&${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    getCustomerDetails().then((d: any) => {
      setCustomerDetails(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          window.location.reload()
        }
      }
    })

    getUserReport(customerId, filters.asOnDate).then((d: any) => {
      setUserReportData(d.data)

      d.data &&
        d.data.forEach((d: any) => {
          d.transactionDate = new Date(d.transactionDate.split('T')[0]).toLocaleDateString('en-GB')
          d.navDate = new Date(d.navDate.split('T')[0]).toLocaleDateString('en-GB')
        })

      try {
        if (!d?.data.length) {
          currentUser.currentUser?.roleId == 1
            ? navigate('/users/generate-transaction-report')
            : navigate('/reports/transactions')
          window.alert('No data found for this user')
        } else {
          // setKeys(Object.keys(userReportData?.data[0]))
          // remove schemeName and schemeId from keys
          setKeys(
            Object.keys(d?.data[0]).filter(
              (d: any) => d !== 'schemeName' && d !== 'schemeId' && d !== 'productType'
            )
          )
        }
      } catch (error) {
        currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-transaction-report')
          : navigate('/reports/transactions')
        window.alert('No data found for this user')
      }
    })
  }, [])

  return (
    <div className=''>
      {/* back button  */}
      <div className='d-flex justify-content-between mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-transaction-report', {
                  state: {
                    customerId,
                    filters,
                  },
                })
              : navigate('/reports/transactions', {
                  state: {
                    customerId,
                    filters,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
        <button
          className='btn btn-light'
          onClick={() => {
            // create a letterhead for print with logo and address
            const letterHead = `
              <div class='container-fluid mb-1 bg-white p-2'>
                <div class='row text-center d-flex align-items-center'>
                  <div class='col-md-3 d-flex ms-0 ps-0'>
                    <img src='/media/logos/MF-sewa2.png' alt='MF-Sewa Logo' style="height:75px; margin-left:20px"/>
                  </div>
                  <div class='col-md-9'>
                    <div class='fs-8'>MF Sewa Kendra</div>
                    <div class='fs-8'>
                      Head Office: MF Sewa, Near Veterinary Hospital, Morinda, Punjab
                    </div>
                    <div class='fs-8'>
                      Our Corporate Office: SCO 333-334, 1st Floor, Cabin No. 208-221, Megabyte Business
                      Centre, Sector 35-B, Chandigarh - 160022
                    </div>
                  </div>
                </div>
              </div>
            `
            const filename = `${allCustomerDetails?.applicantGroupName} - ${new Date(
              filters.asOnDate
            ).toLocaleDateString('en-GB')}`
            const printContents = document.getElementById('printArea')?.innerHTML
            document.body.innerHTML = letterHead + printContents

            document.title = filename
            window.print()
            window.location.reload()
          }}
        >
          Print
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 ps-2' />
        </button>
      </div>

      <div id='printArea'>
        <div className='card card-custom mb-3'>
          <div className='card-header bg-secondary'>
            <div className='card-title'>
              <h3 className='card-label'>
                Transaction Confirmation Statement - As on Date :{' '}
                {new Date(filters.asOnDate).toLocaleDateString('en-GB')}
              </h3>
            </div>
          </div>
          <div className='card-body bg-light d-flex row py-3'>
            <div className='col-md-6 text-start'>
              Client Name : {allCustomerDetails?.applicantGroupName || '-'} (
              <span className='text-primary'>
                <u>{auth?.data?.ucc || '-'}</u>
              </span>
              )
            </div>
          </div>
        </div>

        <div className='px-2' style={{fontSize: '10px'}}>
          <div className='overflow-auto'>
            <table className='table table-hover table-sm position-relative'>
              <thead className='table-info'>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Sr. No
                  </th>
                  {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Trxn Mode
                </th> */}
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Date
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Debit/Payment Date
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Trxn Type
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Exchange
                  </th>
                  {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Partner/Employee
                </th> */}
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Investor
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    AMC
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Scheme
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Folio No
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Trxn ID
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Exc Order ID
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Alloted/Redemption Units
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Investment/Redemption (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Brokerage (₹)
                  </th>
                  {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Trxn Charges (₹)
                </th> */}
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Actual Investment (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Stamp Duty (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Exit Load (₹)
                  </th>
                  {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Tax (₹)
                </th> */}
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Bank Payment
                  </th>
                </tr>
              </thead>
              <tbody>
                {userReportData && userReportData === undefined ? (
                  <tr>
                    <td colSpan={keys.length + 2} className='text-center'>
                      No data found
                    </td>
                  </tr>
                ) : (
                  <>
                    {userReportData &&
                      userReportData.length &&
                      userReportData
                        // .filter((d: any) => d.transactionDate < new Date(filters.asOnDate.split('T')[0]))
                        .map((d: any, i: number, arr: any, key: any) => (
                          <>
                            <tr key={i} className='border border-2 bg-white'>
                              <th scope='row' className='text-center align-middle'>
                                {i + 1}
                              </th>
                              <td className='text-center align-middle'>{d['transactionDate']}</td>
                              <td className='text-center align-middle'>{d['transactionDate']}</td>
                              <td className='text-center align-middle'>{d['productType']}</td>
                              <td className='text-center align-middle'>{d['exchangeName']}</td>
                              <td className='text-center align-middle'>
                                {allCustomerDetails?.applicantName}
                              </td>
                              <td className='text-center align-middle'>{d['amcName']}</td>
                              <td className='text-center align-middle'>{d['schemeName']}</td>
                              <td className='text-center align-middle'>{d['folioNo']}</td>
                              <td className='text-center align-middle'>{d['transactionId']}</td>
                              <td className='text-center align-middle'>{d['exchangeOrderId']}</td>
                              <td className='text-center align-middle'>
                                {Number(d['unitsAssigned']).toLocaleString('en-IN', {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </td>
                              <td className='text-center align-middle'>
                                {Number(d['investmentAmount']).toLocaleString('en-IN', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className='text-center align-middle'>{d['brokerageRate']}</td>
                              <td className='text-center align-middle'>
                                {Number(d['investmentAmount']).toLocaleString('en-IN', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>

                              <td className='text-center align-middle'>{0.005}</td>
                              <td className='text-center align-middle'>{d['exitLoad']}</td>
                              <td className='text-center align-middle'>
                                {Number(d['investmentAmount']).toLocaleString('en-IN', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                          </>
                        ))}
                    {userReportData && userReportData.length && (
                      <tr className='border border-2 bg-white'>
                        <td className='text-center align-middle' colSpan={12}>
                          Total
                        </td>
                        <td className='text-center align-middle'>
                          {userReportData
                            .reduce((a: any, b: any) => Number(a) + Number(b.investmentAmount), 0)
                            .toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>

                        <td className='text-center align-middle'>
                          {userReportData
                            .reduce((a: any, b: any) => Number(a) + Number(b.brokerageRate), 0)
                            .toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>

                        <td className='text-center align-middle'>
                          {userReportData
                            .reduce((a: any, b: any) => Number(a) + Number(b.investmentAmount), 0)
                            .toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td className='text-center align-middle' colSpan={2}></td>

                        <td className='text-center align-middle'>
                          {userReportData
                            .reduce((a: any, b: any) => Number(a) + Number(b.investmentAmount), 0)
                            .toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-sm-12 py-3 px-6 border-top-3 border-dark text-dark bg-white'>
              <h5>
                <b>Note :</b>
              </h5>
              <ol type='1'>
                <li>Switch In and Switch Out indicates AMC Switch Transactions.</li>
                <li>Purchase(SWI) and Redemption(SWI) indicated MF Sewa Switch Transactions.</li>
                <li>Please note that Debit/Payment date may vary from your bank statement.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TransactionReport = () => (
  <Routes>
    <Route element={<Report />}>
      <Route path='users/generate-transaction-report/:id' element={<Report />} />
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {TransactionReport}
