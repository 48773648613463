import React from 'react'
import toastr from 'toastr';
type Props = {
    messageType: string,
    message : string,
    statusCode : number
  }

  console.log('this is called at 10')
const ToastifyWidget: React.FC<Props> = ({messageType,message,statusCode}) => {
  toastr.options = {
    "closeButton": true,
    "debug": true,
    "positionClass": "toast-top-center",
    "showDuration": 1000,
    "hideDuration": 1000,
    "timeOut": 5000,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }
  return (
    <>
  { messageType === 'success' ?
  (<> 
  {  toastr['success'](message, '')
      }
        </>)
        :  (<>
        
      { toastr['error'](message,'')}
        
       
        </>)
        }
          
          </>
  )
   
}

export {ToastifyWidget}