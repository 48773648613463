import * as Yup from 'yup'

export interface ICreateAccount {
  customerSelect: string
  product: string
  exchange: string
  type: string[]
  subType: string[]
  amc: string[]
  trxnMode: string[]
  trxnType: string[]
  scheme: string[]
  asOnDate: string
  startDate:string
  endDate:string
}

const createAccountSchemas = [
  Yup.object({
    customerSelect: Yup.string()
      .required('Please select a user to proceed')
      .label('Customer Select')
      .notOneOf(['', null, undefined]),
  }),
  Yup.object().shape({
    product: Yup.string().label('Product Type').required(),
    exchange: Yup.string().label('Exchange Type').required(),
    type: Yup.array().of(Yup.string()).label('Scheme Type'),
    subType: Yup.array().of(Yup.string()).label('Scheme Sub Type'),
    amc: Yup.array().of(Yup.string()).label('AMC'),
    trxnMode: Yup.array().of(Yup.string()).label('Transaction Mode'),
    trxnType: Yup.array().of(Yup.string()).label('Transaction Type'),
    scheme: Yup.array().of(Yup.string()).label('Scheme'),
    asOnDate: Yup.string().label('As on Date'),
    startDate: Yup.string().label('Start Date'),
    endDate: Yup.string().label('End Date'),
  }),
]

const inits: ICreateAccount = {
  customerSelect: '',
  product: 'all',
  exchange: 'all',
  type: [],
  subType: [],
  amc: [],
  trxnMode: [],
  trxnType: [],
  scheme: [],
  asOnDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
    .toISOString()
    .split('T')[0],
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
    .toISOString()
    .split('T')[0],
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
    .toISOString()
    .split('T')[0],
}

export {createAccountSchemas, inits}
