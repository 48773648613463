import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../user-management/users-list/core/_models'
import {getAuth} from '../../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../auth/core/Auth'
import {ToastifyWidget} from '../../../../../_metronic/partials/widgets'
// import moment from 'moment'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/get_customer_details`
const GET_VALUE_REPORT = `${API_URL}/customer/get_overall_report`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {customerId, asOnDate, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const [userReport, setUserReport] = useState<any[]>([])
  const [userReportMeta, setUserReportMeta] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()

  // convert filters to string to pass in url
  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  console.log('filters:', applyFilters)

  const getCustomerDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}/${customerId}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getUserReport = (customerId: any, asOnDate: String): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_VALUE_REPORT}?userId=${customerId}&${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    getCustomerDetails().then((d: any) => {
      setCustomerDetails(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          window.location.reload()
        }
      }
    })

    getUserReport(customerId, filters.asOnDate).then((d: any) => {
      setUserReport(d.data)
      // console.log('report++++++++++', d.meta)
      setUserReportMeta(d.meta)
      d.data &&
        d.data.forEach((d: any) => {
          d.transactionDate = new Date(d.transactionDate.split('T')[0]).toLocaleDateString('en-GB')
          d.navDate = new Date(d.navDate.split('T')[0]).toLocaleDateString('en-GB')
        })

      try {
        if (!d?.data.length) {
          currentUser.currentUser?.roleId == 1
            ? navigate('/users/generate-customer-report')
            : navigate('/reports/current-holdings')
          window.alert('No data found for this user')
        } else {
          // setKeys(Object.keys(userReport?.data[0]))
          // remove schemeName and schemeId from keys
          setKeys(
            Object.keys(d?.data[0]).filter(
              (d: any) => d !== 'schemeName' && d !== 'schemeId' && d !== 'productType'
            )
          )
        }
      } catch (error) {
        currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-customer-report')
          : navigate('/reports/current-holdings')
        window.alert('No data found for this user')
      }
    })
  }, [])

  return (
    <div className='overflow-hidden'>
      {/* back button  */}
      <div className='d-flex justify-content-between mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-customer-report', {
                  state: {
                    customerId,
                    filters,
                    asOnDate,
                  },
                })
              : navigate('/reports/current-holdings', {
                  state: {
                    customerId,
                    filters,
                    asOnDate,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>

        <button
          className='btn btn-light'
          onClick={() => {
            // create a letterhead for print with logo and address
            const letterHead = `
              <div class='container-fluid mb-1 bg-white p-2'>
                <div class='row text-center d-flex align-items-center'>
                  <div class='col-md-3 d-flex ms-0 ps-0'>
                    <img src='/media/logos/MF-sewa2.png' alt='MF-Sewa Logo' style="height:75px; margin-left:20px"/>
                  </div>
                  <div class='col-md-9'>
                    <div class='fs-8'>MF Sewa Kendra</div>
                    <div class='fs-8'>
                      Head Office: MF Sewa, Near Veterinary Hospital, Morinda, Punjab
                    </div>
                    <div class='fs-8'>
                      Our Corporate Office: SCO 333-334, 1st Floor, Cabin No. 208-221, Megabyte Business
                      Centre, Sector 35-B, Chandigarh - 160022
                    </div>
                  </div>
                </div>
              </div>
            `
            const filename = `${allCustomerDetails?.applicantGroupName} - ${new Date(
              filters.asOnDate
            ).toLocaleDateString('en-GB')}`
            const printContents = document.getElementById('printArea')?.innerHTML
            document.body.innerHTML = letterHead + printContents

            document.title = filename
            window.print()
            window.location.reload()
          }}
        >
          Print
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 ps-2' />
        </button>
      </div>

      <div id='printArea'>
        <div className='card card-custom mb-5'>
          <div className='card-header bg-secondary'>
            <div className='card-title'>
              <h3 className='card-label'>
                Scheme Wise - Valuation Report As on Date :{' '}
                {new Date(filters.asOnDate).toLocaleDateString('en-GB')}
              </h3>
            </div>
          </div>
          <div className='card-body d-flex row'>
            <div className='col-md-6 text-start'>
              <div className='mb-2'>
                <span className='fs-5 text-primary'>
                  <u>{allCustomerDetails?.applicantGroupName || '-'}</u>
                </span>
              </div>
              <br />
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Address: &ensp;</span>
                <span className='text-muted'>{allCustomerDetails?.applicantAddress}</span>
              </div>
              <div className='d-flex pb-2'>
                <span className='fw-bold w-30'>City: &ensp;&emsp;&emsp;</span>
                <span className='text-muted'>{allCustomerDetails?.city || '-'}</span>
              </div>
              <div className='d-flex pb-2'>
                <span className='fw-bold w-30'>Pincode: &ensp;</span>
                <span className='text-muted'>{allCustomerDetails?.pinCode || '-'}</span>
              </div>
              <div className='d-flex pb-2'>
                <span className='fw-bold w-30'>Email:&emsp;&emsp;</span>
                <span className='text-muted'>{allCustomerDetails?.email || '-'}</span>
              </div>
              <div className='d-flex pb-2'>
                <span className='fw-bold w-30'>Mobile: &emsp;</span>
                <span className='text-muted'>{allCustomerDetails?.mobileNo || '-'}</span>
              </div>
            </div>

            <div className='col-md-6 text-start'>
              <div className='mb-2'>
                <span className='fs-5 text-primary'>
                  <u>Your Relationship Handler</u>
                </span>
              </div>
              <br />
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Name: &emsp;&emsp;</span>
                <span className='text-muted'>Amritpal Singh</span>
              </div>
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Address: &emsp;</span>
                <span className='text-muted w-75'>
                  H.NO 124, OLD BASSI ROAD, OPP - VETERINARY HOSPITAL, WARD NO 12
                </span>
              </div>
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>City: &emsp;&emsp;&emsp;</span>
                <span className='text-muted'>MORINDA</span>
              </div>
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Pincode: &emsp;</span>
                <span className='text-muted'>140101</span>
              </div>
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Mobile: &emsp;&ensp;&nbsp;</span>
                <span className='text-muted'>9872956403</span>
              </div>
              <div className='d-flex pb-3'>
                <span className='fw-bold w-30'>Email: &emsp;&emsp;&nbsp;</span>
                <span className='text-muted'>AMRIT24X7@gmail.com</span>
              </div>
            </div>
          </div>
        </div>

        <div className='px-2' style={{fontSize: '10px'}}>
          <table className='table table-hover table-sm position-relative'>
            <thead className='table-info'>
              <tr className='border border-2 border-secondary'>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Sr. No
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Investor
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  UCC
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  ISIN
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Folio No
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Date
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Sub Type
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Amount (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  NAV (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  No of Units
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  NAV Date
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Trn Days
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Current NAV (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Current Value (₹)
                </th>

                <th className='text-center align-middle sticky-top' scope='col'>
                  Total (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Annualized Return (%)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Abs. Return (%)
                </th>
              </tr>
            </thead>
            <tbody>
              {userReport && userReport === undefined ? (
                <tr>
                  <td colSpan={keys.length + 2} className='text-center'>
                    No data found
                  </td>
                </tr>
              ) : (
                <>
                  {userReport &&
                    userReport.length &&
                    userReport
                      // .filter((d: any) => d.transactionDate < new Date(filters.asOnDate.split('T')[0]))
                      .map((d: any, i: number, key: any) => (
                        <>
                          {((i > 0 && d.schemeId !== userReport[i - 1].schemeId) || i === 0) && (
                            <tr key={key} className='bg-white'>
                              <th
                                className='p-2 bg-white text-dark fw-bold fs-6'
                                colSpan={keys.length + 2}
                              >
                                {d.schemeName}
                              </th>
                              {/* <th className='p-2 bg-light text-dark fw-bold fs-6' colSpan={keys.length}>
                            Returns:{' '}
                            {Number(
                              userReport.meta.schemesGroupXIRR.find(
                                (s: any) => s.schemeId === d.schemeId
                              )?.xirr
                            ).toFixed(2) + '%'}
                          </th> */}
                            </tr>
                          )}

                          <tr key={i} className='border border-2 bg-white'>
                            <th scope='row' className='text-center align-middle'>
                              {i + 1}
                            </th>
                            <td className='text-center align-middle'>
                              {allCustomerDetails?.applicantName}
                            </td>
                            <td className='text-center align-middle'>{d['ucc']}</td>
                            <td className='text-center align-middle'>{d['isin']}</td>
                            <td className='text-center align-middle'>{d['folioNo']}</td>
                            <td className='text-center align-middle'>{d['transactionDate']}</td>
                            <td className='text-center align-middle'>{d['exchangeSubType']}</td>
                            <td className='text-center align-middle'>
                              {Number(d['currentIvestmentAmount']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['puchaseNavRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['unitsPending']).toLocaleString('en-IN', {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </td>
                            <td className='text-center align-middle'>{d['navDate']}</td>
                            <td className='text-center align-middle'>{d['txnDays']}</td>
                            <td className='text-center align-middle'>
                              {Number(d['currentRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentValue']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentValue']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['CAGR']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['simpleReturn']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>

                          {((i < userReport.length - 1 &&
                            d.schemeId !== userReport[i + 1].schemeId) ||
                            i === userReport.length - 1) && (
                            <>
                              <tr className='border border-2 border-secondary bg-white'>
                                <th
                                  scope='row'
                                  className='p-2 align-middle fw-bold'
                                  colSpan={7}
                                  style={{fontSize: '12px'}}
                                >
                                  Sub Total
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {userReport
                                    .filter((a: any) => a.schemeId === d.schemeId)
                                    .reduce(
                                      (a: any, b: any) =>
                                        Number(a) + Number(b.currentIvestmentAmount),
                                      0
                                    )
                                    .toLocaleString('en-IN', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {/* arr
                                .filter((a: any) => a.schemeId === d.schemeId)
                                .reduce(
                                  (a: any, b: any) =>
                                    (Number(a) + Number()).toFixed(4),
                                  0
                                ) */}
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {userReport
                                    .filter((a: any) => a.schemeId === d.schemeId)
                                    .reduce(
                                      (a: any, b: any) => Number(a) + Number(b.unitsPending),
                                      0
                                    )
                                    .toLocaleString('en-IN', {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}
                                </th>
                                <th className='text-center align-middle fw-bold'></th>
                                <th className='text-center align-middle fw-bold'></th>
                                <th className='text-center align-middle fw-bold'></th>
                                <th className='text-center align-middle fw-bold'>
                                  {userReport
                                    .filter((a: any) => a.schemeId === d.schemeId)
                                    .reduce(
                                      (a: any, b: any) => Number(a) + Number(b.currentValue),
                                      0
                                    )
                                    .toLocaleString('en-IN', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {userReport
                                    .filter((a: any) => a.schemeId === d.schemeId)
                                    .reduce(
                                      (a: any, b: any) => Number(a) + Number(b.currentValue),
                                      0
                                    )
                                    .toLocaleString('en-IN', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {/* testABs {Number(d.simpleReturn).toFixed(2)} */}
                                </th>
                                <th className='text-center align-middle fw-bold'>
                                  {/* test {Number(d.CAGR).toFixed(2)} */}
                                </th>
                              </tr>
                              <tr className='border border-2 border-secondary '>
                                <th
                                  scope='row'
                                  className='p-2 align-middle fw-bold text-center'
                                  colSpan={keys.length + 4}
                                >
                                  Return:{' '}
                                  {userReportMeta ? (
                                    <>
                                      {' '}
                                      {userReport.filter((a: any) => a.schemeId === d.schemeId)
                                        .length > 1 &&
                                      userReportMeta.schemesGroupXIRR.filter(
                                        (a: any) => a.schemeId === d.schemeId
                                      ).length > 0
                                        ? Number(
                                            userReportMeta.schemesGroupXIRR.find(
                                              (s: any) => s.schemeId === d.schemeId
                                            )?.xirr
                                          ).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : Number(d.CAGR).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}{' '}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  % &ensp;|&ensp; Weighted Avg. Abs. Return:{' '}
                                  {/* {(
                                arr
                                  .filter((a: any) => a.schemeId === d.schemeId)
                                  .reduce(
                                    (a: any, b: any) => Number(a) + Number(b.simpleReturn),
                                    0
                                  ) / arr.filter((a: any) => a.schemeId === d.schemeId).length
                              ).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })} */}
                                  {(
                                    ((userReport
                                      .filter((a: any) => a.schemeId === d.schemeId)
                                      .reduce(
                                        (a: any, b: any) => Number(a) + Number(b.currentValue),
                                        0
                                      ) -
                                      userReport
                                        .filter((a: any) => a.schemeId === d.schemeId)
                                        .reduce(
                                          (a: any, b: any) =>
                                            Number(a) + Number(b.investmentAmount),
                                          0
                                        )) /
                                      userReport
                                        .filter((a: any) => a.schemeId === d.schemeId)
                                        .reduce(
                                          (a: any, b: any) =>
                                            Number(a) + Number(b.investmentAmount),
                                          0
                                        )) *
                                    100
                                  ).toLocaleString('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  % &ensp;|&ensp; Gain/Loss: ₹{' '}
                                  {userReport
                                    .filter((a: any) => a.schemeId === d.schemeId)
                                    .reduce((a: any, b: any) => Number(a) + Number(b.gain), 0)
                                    .toLocaleString('en-IN', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </th>
                              </tr>
                            </>
                          )}
                        </>
                      ))}

                  <tr className='border border-2 border-secondary bg-white'>
                    <th
                      scope='row'
                      className='p-2 align-middle fw-bold'
                      colSpan={7}
                      style={{fontSize: '12px'}}
                    >
                      Grand Total
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.currentIvestmentAmount), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {/* userReport.data
                                .reduce(
                                  (a: any, b: any) =>
                                    (Number(a) + Number()).toFixed(4),
                                  0
                                ) */}
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.unitsPending), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                    </th>
                    <th className='text-center align-middle fw-bold'></th>
                    <th className='text-center align-middle fw-bold'></th>
                    <th className='text-center align-middle fw-bold'></th>
                    <th className='text-center align-middle fw-bold'>
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.currentValue), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.currentValue), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {/*Number(d.simpleReturn).toFixed(2)*/}
                    </th>
                    <th className='text-center align-middle fw-bold'>
                      {/*Number(d.CAGR).toFixed(2)*/}
                    </th>
                  </tr>
                  <tr className='border border-2 border-secondary'>
                    <th
                      scope='row'
                      className='p-2 align-middle fw-bold text-center'
                      colSpan={keys.length + 4}
                    >
                      Return:{' '}
                      {userReport.length > 1 && userReportMeta
                        ? Number(userReportMeta.allSchemesXIRR).toLocaleString('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : userReport && userReport.length
                        ? Number(userReport[0].CAGR).toLocaleString('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                      % &ensp;|&ensp; Weighted Avg. Abs. Return:{' '}
                      {/* {(
                      userReport.data.reduce(
                        (a: any, b: any) => Number(a) + Number(b.simpleReturn),
                        0
                      ) / userReport.data.length
                    ).toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} */}
                      {(
                        ((userReport.reduce(
                          (a: any, b: any) => Number(a) + Number(b.currentValue),
                          0
                        ) -
                          userReport.reduce(
                            (a: any, b: any) => Number(a) + Number(b.investmentAmount),
                            0
                          )) /
                          userReport.reduce(
                            (a: any, b: any) => Number(a) + Number(b.investmentAmount),
                            0
                          )) *
                        100
                      ).toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      % &ensp;|&ensp; Gain/Loss: ₹{' '}
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.gain), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </th>
                  </tr>
                  <tr className='border-top-2'>
                    <th
                      className='p-5 bg-secondary text-dark text-center fw-bolder fs-7'
                      colSpan={keys.length * 2}
                    >
                      Current Value of your Total Investment is &nbsp;₹{' '}
                      {userReport
                        .reduce((a: any, b: any) => Number(a) + Number(b.currentValue), 0)
                        .toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                      /-
                    </th>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <div className='row'>
            <div className='col-sm-12 py-3 px-6 border-top-3 border-dark text-dark bg-white'>
              <h5>
                <b>Note :</b>
              </h5>
              <ol type='1'>
                <li>Folio No. with * symbol indicate Destatementized Folios.</li>
                <li>
                  Annualized Return is calculated on an Absolute basis for less than 1 Year And on
                  XIRR basis for greater than or equal to 1 Year.
                </li>
                <li>
                  For Scheme merger cases, Purchase trade's (SWI) Amount, NAV & Units are displayed
                  in blue color font with underline.
                </li>
                <li>
                  For Scheme merger cases, Eventhough the scheme name is being displayed is of the
                  new scheme, the trxn data are of the old schemes. Purchase NAV & Units have been
                  adjusted to derive the actual investment amount.
                </li>
                <li>
                  ISIN column provides a number which is a unique identifier for the schemes which
                  are listed on the stock exchange(s). ISIN value is being displayed irrespective of
                  the Transaction Mode. Further, If the investment has been made into the Dividend
                  Option scheme(s) then the ISIN will get displayed is of the Latest Option opted on
                  that Scheme + Folio No.
                </li>
                <li>
                  This Report is Strictly Private and confidential only for clients of MF Sewa
                  Kendra. The Information given above is correct and to the best of our knowledge.
                  For any discrepancy on the same contact the nearest Office of MF Sewa Kendra.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ValuationReport = () => (
  <Routes>
    <Route element={<Report />}>
      <Route path='users/generate-customer-report/:id' element={<Report />} />
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {ValuationReport}
