import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../../app/modules/auth/core/Auth'
import {UsersQueryResponse} from '../../../../modules/apps/user-management/users-list/core/_models'
import axios, {AxiosResponse} from 'axios'
import {getAuth} from '../../../../../app/modules/auth'

interface Step2Props {
  customerId: any
}

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments`
const GET_SCHEMES_URL = `${API_URL}/products/get_products`

const GET_TYPE_URL = `${API_URL}/admin/exchange_types`
const GET_SUB_TYPE_URL = `${API_URL}/admin/exchange_sub_types`
const GET_AMC_URL = `${API_URL}/admin/amc`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const getType = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_TYPE_URL}?${auth?.data.id}`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getSubType = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_SUB_TYPE_URL}?limit=100`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getAmc = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_AMC_URL}?limit=100`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getSchemes = () => {
  return axios.get(`${GET_SCHEMES_URL}?limit=100`, config).then((d) => d.data)
}

const getUserInvestments = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_INVESTMENT_URL}`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const Step2: FC<Step2Props> = ({customerId}) => {
  const currentUser = useAuth()
  const [userInvestments, setUserInvestments] = useState<any[]>([])
  const [schemes, setSchemes] = useState<any[]>([])
  const [types, setTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])
  const [amcs, setAmcs] = useState<any[]>([])

  useEffect(() => {
    let temp: any = []
    getUserInvestments()
      .then((d: any) => {
        d.data
          .filter((d: any) => d.customerId == customerId && d.status === 'approved')
          .map((d: any) => {
            temp.push(d)
          }, [])
      })
      .then(() => {
        setUserInvestments(temp)
      })
  }, [customerId])

  useEffect(() => {
    getType().then((d: any) => {
      setTypes(d.data)
    })
  }, [types?.length])

  useEffect(() => {
    getSubType().then((d: any) => {
      d.data.sort((a: any, b: any) => a.id - b.id)
      setSubTypes(d.data)
    })
  }, [subTypes?.length])

  useEffect(() => {
    getAmc().then((d: any) => {
      d.data.sort((a: any, b: any) => a.id - b.id)
      setAmcs(d.data)
    })
  }, [amcs?.length])

  useEffect(() => {
    getSchemes().then((d: any) => {
      d.data.filter((d: any) => d?.status === 'approved')
      setSchemes(d.data)
    })
  }, [schemes?.length])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Select your preferences for the report</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          {userInvestments[0]?.applicantGroupName}
          <a href='/dashboard' className='link-primary fw-bolder'>
            {` (${userInvestments[0]?.ucc ? userInvestments[0]?.ucc : '-'})`}
          </a>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row mb-4'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2'>AMC</label>

            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='amc'
              placeholder='Select AMC'
            >
              <option value=''>Select AMC</option>
              <option value='all'>All</option>
              <option value='nse'>NSE</option>
              <option value='bse'>BSE</option>
            </Field>

            <div className='text-danger mt-2'>
              <ErrorMessage name='amc' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2'>Scheme</label>

            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='scheme'
              placeholder='Select Scheme'
            >
              <option value=''>Select Scheme</option>
              <option value='all'>All</option>
              <option value='nse'>NSE</option>
              <option value='bse'>BSE</option>
            </Field>

            <div className='text-danger mt-2'>
              <ErrorMessage name='scheme' />
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2'>Folio Status</label>

            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='folioStatus'
              placeholder='Select Folio Status'
            >
              <option value=''>Select Folio Status</option>
              <option value='all'>All</option>
              <option value='nse'>NSE</option>
              <option value='bse'>BSE</option>
            </Field>

            <div className='text-danger mt-2'>
              <ErrorMessage name='folioStatus' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label htmlFor='asOnDate' className='text-dark fw-bold fs-6 mb-2'>
              As on Date
            </label>
            <Field
              type='date'
              className='form-control border border-primary'
              name='asOnDate'
              max={
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
                  .toISOString()
                  .split('T')[0]
              }
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='asOnDate' />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label htmlFor='asOnDate' className='text-dark fw-bold fs-14'>
              As on Date
            </label>
            <Field
              type='date'
              className='form-control border border-primary'
              name='asOnDate'
              max={
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
                  .toISOString()
                  .split('T')[0]
              }
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='asOnDate' />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export {Step2}
