/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {getAuth} from '../../../../app/modules/auth/core/AuthHelpers'
import {KTSVG} from '../../../helpers'
import {Dropdown4} from '../../content/dropdown/Dropdown4'
import {
  User,
  UsersQueryResponse,
} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import axios from 'axios'

type Props = {
  className: string
}

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
//const INVESTMENT_URL = `${API_URL}/admin/exchange_types`
//const GET_USERS_LIST_URL = `${API_URL}/admin/users_list`
const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments`
const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const TablesWidget14: React.FC<Props> = ({className}) => {
  const statusColor: any = {
    submitted: 'primary',
    in_process: 'warning',
    approved: 'success',
    rejected: 'danger',
  }

  const [tableData, setTableData] = useState<User[]>([])
  const [trigger, setTrigger] = useState(false)

  const getInvestments = (query: string) => {
    axios.get(`${GET_INVESTMENT_URL}?${query}`, config).then((response) => {
      setTableData(response.data.data)
    })
  }

  useEffect(() => {
    const query = ''
    getInvestments(query)
  }, [])
  // useEffect(() => {}, [trigger])

  return (
    <div className={`card ${className}`}>
      <script></script>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>My Recent Transactions</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {
              tableData.filter(
                (item) =>
                  item.customerId === auth?.data.id &&
                  (item.status === 'submitted' || item.status === 'in_process')
              ).length
            }{' '}
            recent investments
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 overflow-auto' style={{maxHeight: '40rem'}}>
        {/* begin::Table container */}
        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Folio No.</th>
                <th className='min-w-140px'>Scheme</th>
                <th className='min-w-120px'>Amount (₹)</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-120px'>NAV (₹)</th>
                <th className='min-w-120px'>Status</th>
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='overflow-auto'>
              {tableData
                .filter(
                  (item) =>
                    item.customerId === auth?.data.id &&
                    (item.status === 'submitted' || item.status === 'in_process')
                )
                .map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-13-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item.folioNo != '' || null ? item.folioNo : '-'}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item.schemeName}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {item.productType}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item.investmentAmount}
                      </a>
                      {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                      Lock-in: {item.lockInPeriod}
                    </span> */}
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(`${item.startDate}`).toLocaleDateString('en-GB')}
                      </a>
                    </td>
                    <td className='text-dark fw-bolder text-hover-primary fs-6'>{item.price}</td>
                    <td id={`row` + String(item.id)}>
                      <span
                        className={`badge badge-light-${
                          statusColor[item.status ? item.status : 'primary']
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>
                    {/* <td className='text-end'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </button>
                    <Dropdown4 item={tableData[item.id - 1]} setTableItem={setTableItem} />

                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </td> */}
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget14}
