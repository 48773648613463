/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../helpers'
import {Dropdown8} from '../../content/dropdown/Dropdown8'

//import {ID, Response} from '../../../../../../../_metronic/helpers'
// import {
//   User,
//   UsersQueryResponse,
// } from '../../../../app/modules/apps/user-management/users-list/core/_models'
//import {User, UsersQueryResponse} from './_models'
import {getAuth} from '../../../../app/modules/auth/core/AuthHelpers'
//import {getAuth} from '../../../../auth/core/AuthHelpers'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
//const INVESTMENT_URL = `${API_URL}/admin/exchange_types`
//const GET_USERS_LIST_URL = `${API_URL}/admin/users_list`
// const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments`
const GET_UPCOMING_MATURITIES_URL = `${API_URL}/customer/upcoming_maturities`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

type Props = {
  className: string
}

const TablesWidget15: React.FC<Props> = ({className}) => {
  const statusColor: any = {
    submitted: 'primary',
    in_process: 'warning',
    approved: 'success',
    rejected: 'danger',
  }

  const [tableData, setTableData] = useState<any[]>([])

  const [triggerTwo, setTriggerTwo] = useState(false)

  // const getInvestments = (query: string) => {
  //   axios.get(`${GET_INVESTMENT_URL}?${query}`, config).then((response) => {
  //     if (response.data.sessionExpired) {
  //     } else setTableData(response.data.data)
  //   })
  // }

  const getMaturities = (query: string) => {
    axios.get(`${GET_UPCOMING_MATURITIES_URL}?${query}`, config).then((response) => {
      // if (response.data.sessionExpired) {
      if (response.data.errorCode == 601) {
        var allCookies = document.cookie.split(';')

        // The "expire" attribute of every cookie is
        // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
        for (var i = 0; i < allCookies.length; i++)
          document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString()

        // alert(document.cookie)
        // clear all cookies
        // document.cookie.split(';').forEach(function (c) {
        //   document.cookie = c
        //     .replace(/^ +/, '')
        //     .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        // })
        // window.localStorage.clear()
        window.location.reload()
      } else setTableData(response.data.data)
    })
  }

  /*const [trigger, setTrigger] = useState(false)
  const setTableItem = (id: number, element: string, value: string) => {
    var tempTable = tableData
    const currentItem: any = tempTable[id - 1]
    currentItem[element] = value
    tempTable[id - 1] = currentItem
    // console.log(currentItem)
    setTableData(tempTable)
    setTrigger(!trigger)
  }
*/
  // const setTableItem = '1'

  // const formatDate = (date: Date) => {
  //   function pad(s: any) {
  //     return s < 10 ? '0' + s : s
  //   }
  //   var d = new Date(date)
  //   return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
  // }

  useEffect(() => {
    const query = ''
    getMaturities(query)
  }, [triggerTwo])

  return (
    <div className={`card ${className}`}>
      <script></script>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Upcoming Maturities</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {tableData.length} upcoming maturities
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 overflow-auto' style={{maxHeight: '40rem'}}>
        {/* begin::Table container */}
        <div className='table-responsive table-sticky-header' style={{maxHeight: '40rem'}}>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}
                <th className='w-25px' scope='col'>
                  Sr. No.
                </th>
                <th className='min-w-150px'>Group Name</th>
                <th className='min-w-120px'>UCC</th>
                <th className='min-w-140px'>Scheme</th>
                <th className='min-w-130px'>Amount</th>
                <th className='min-w-130px text-center'>Investment Date</th>
                <th className='min-w-130px text-center'>Maturity Date</th>
                <th className='min-w-130px'>Term</th>
                <th className='min-w-150px'>Current Value</th>
                <th className='min-w-130px'>Due Date</th>
                {/* <th className='min-w-60px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='overflow-auto'>
              {tableData
                // .filter((item) => item.status === 'submitted' || item.status === 'in_process')
                .sort((a: any, b: any) => (a.maturityDate > b.maturityDate ? 1 : -1))
                .map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className='text-dark fw-bolder text-center fs-6'>{key + 1}</div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item?.applicantGroupName}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item?.ucc}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.schemeName}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {/* {item.schemeName} */}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.investmentAmount}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-8'>
                        Route: {item?.investmentRoute}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(`${item?.transactionDate}`).toLocaleDateString('en-GB')}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(`${item?.maturityDate}`).toLocaleDateString('en-GB')}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {/* {new Date(`${item.maturityDate}`).toLocaleDateString('en-GB')} */}
                        {item?.installments}
                      </a>
                    </td>
                    <td className='text-dark fw-bolder text-center text-hover-primary fs-6'>
                      {item.currentValue}
                    </td>
                    <td className='text-dark fw-bolder text-center text-hover-primary fs-6'>
                      {new Date(`${item?.maturityDate}`).toLocaleDateString('en-GB')}
                    </td>
                    {/* <td id={`row` + String(item.id)}>
                      <span
                        className={`badge badge-light-${
                          statusColor[item.status ? item.status : 'primary']
                        }`}
                      >
                        {item.status}
                      </span>
                    </td> */}
                    {/* <td className='text-end'> */}
                    {/*<button
                      onClick={() => {
                        editing = !editing
                        
                        editStatus(item.id)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      {editing ? (
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr085.svg'
                          className='svg-icon-3'
                        />
                      ) : (
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      )}
                      </button> */}
                    {/* begin::Menu */}
                    {/* <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </button>
                      <Dropdown8
                        item={item}
                        triggerTwo={triggerTwo}
                        setTriggerTwo={setTriggerTwo}
                      /> */}
                    {/* end::Menu */}
                    {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
                    {/* </td> */}
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget15}
