/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const MFPartner: FC = () => {
  return (
    <div className='modal fade' id='kt_modal_mf_partner' aria-hidden='true'>
      <div className='modal-dialog model-dialog-scrolable modal-dialog-centered '>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>MF Sewa Partner Information</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body'>
            <div className='container d-flex justify-content-center'>
              <div className='card p-4'>
                <div className=' image d-flex flex-column justify-content-center align-items-center'>
                  <img
                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                    className='border rounded'
                    height='120'
                    width='120'
                  />
                  <span className='name mt-3 fw-bold fs-5 text-primary'>Amritpal Singh</span>
                  <span className='text-muted'>MF Sewa Partner</span>
                  <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                    <span className='text-muted mt-3'>Company: </span>
                    <span className='mt-3 fw-bold'>MF SEWA KENDRA</span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='d-flex mt-3'>
                    <span className='w-50 ms-2 me-5 text-muted text-start'>License:</span>
                    <span className='min-w-75 text-start fw-bold'>
                      &ensp;ARN-106884
                      <br />
                      &ensp;EUIN-E143730
                      <br />
                      &ensp;MF Code - 72865
                    </span>
                  </div>
                  <div className='gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center'>
                    <img
                      src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm008.svg')}
                      alt='logo'
                      width='25'
                      height='25'
                    />
                  </div>
                </div>
                <div
                  className='d-flex justify-content-between pt-6 text-primary'
                  style={{width: '20rem', fontSize: '12px'}}
                >
                  <a
                    href='tel:+919872956403'
                    className='w-50 text-center me-5 d-flex justify-content-center align-items-center'
                  >
                    <i className='fas fa-phone-alt me-2'></i>
                    9872956403
                  </a>
                  <a
                    href='mailto:amrit24x7@gmail.com'
                    className='w-75 text-center ms-5 d-flex justify-content-center align-items-center'
                  >
                    <i className='fas fa-envelope me-2'></i>
                    AMRIT24X7@GMAIL.COM
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MFPartner}
