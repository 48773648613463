import React, {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {PageTitle} from '../../../_metronic/layout/core'

const Terms: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Terms of Use</PageTitle>
      <div className='content flex-column-fluid' id='kt_content'>
        <div className='card'>
          <div className='card-body p-lg-17'>
            <div className='row mb-3 d-flex justify-content-evenly'>
              <div className='col-md-12 pe-lg-10'>
                <h2 className='text-center mb-6'>Terms of Use</h2>
                <p style={{textAlign: "justify"}}>
                  This Terms of User Agreement (“User Agreement”) lays out the terms and conditions
                  for governing the user’s access and use of services on the Website/Client Desk/
                  Partner Desk/ Mobile Application including any other sub-domain or platform which
                  is owned, developed, managed or operated by MF Sewa Kendra Private Limited ("MF
                  Sewa"), and/or its affiliates (hereinafter referred to as "Website"). By using or
                  accessing the Website, using the services, the User accepts and agrees to be bound
                  by the Terms of Use. If the User does not agree with the Terms of Use, then the
                  User may immediately exit the Website.
                </p>
                <p style={{textAlign: "justify"}}>
                  In terms of the Information Technology Act, 2000, this document is an electronic
                  record, being generated by a computer system, and it does not require any physical
                  or digital signature. Your acceptance of the terms and conditions shall be
                  considered as your consent to use the App, the Website, the services and products
                  (collectively referred to herein as "Services") provided by MF Sewa in accordance
                  with the terms and conditions laid down by MF Sewa.
                </p>
                <p style={{textAlign: "justify"}}>
                  This User Agreement is published in accordance with the Rule 3 (1) of the
                  Information Technology (Intermediaries guidelines) Rules, 2011 that requires
                  intermediaries to publish the rules and regulations, privacy policy and user
                  agreement for access or usage of the intermediary's computer resource by Users.
                </p>

                <h5 className='mt-7 mb-4'>About MF Sewa</h5>
                <p style={{textAlign: "justify"}}>
                  MF Sewa is engaged in the business of financial product distribution and allied
                  services. MF Sewa and its MF Sewa Wealth Partners are mutual fund distributors
                  registered with AMFI. In accordance with the applicable laws, MF Sewa and MF Sewa
                  Wealth Partner are permitted to only render incidental advice with respect to
                  mutual fund products only to its mutual fund distribution client. For every other
                  purpose, including distribution of non-mutual fund products, all material
                  displayed on the Website is for informational purposes only. Further, it is not
                  intended as investment advice or an opinion concerning securities or a public
                  offer proving a basis for an investment decision. No material /information
                  contained on the Website constitutes or shall be interpreted as investment advice,
                  offer, solicitation or advertising with respect to the purchase or sale of any
                  security and no part of it shall form the basis of or be relied upon in connection
                  with any contract or commitment whatsoever. MF Sewa and/or MF Sewa Wealth
                  Partners, in association with MF Sewa, may also be engaged in 'distribution only'
                  of other financial and investment products, including but not limited to portfolio
                  management products, bank and corporate fixed deposits, bonds, gold bonds,
                  convertible debentures, debentures, national pension scheme and loan products,
                  etc. as available/ offered on primary and secondary security markets and/or in
                  direct arrangement with such third parties issuing/providing such products. Each
                  of such activities is done independently. MF Sewa and MF Sewa Wealth Partners do
                  not provide any advice with respect to such products and the activity is strictly
                  restricted to distribution only under extant applicable laws. MF Sewa and MF Sewa
                  Wealth Partner do not provide any investment advisory services w.r.t. such
                  non-mutual fund products and any information provided thereof should not be relied
                  upon while making an investment in such investment products.
                </p>

                <h5 className='mt-7 mb-4'>Interpretation</h5>

                <p style={{textAlign: "justify"}}>
                  The words, "him", "his", "You", "Your", "Client", "User" "Investor", "Subscriber"
                  and "Customer" refer to the person(s) who use and avail the Services and Products
                  of MF Sewa Website / App and shall include both singular and plural. All headings,
                  bold typing and italics (if any) have been inserted for convenience of reference
                  only and do not define, limit or affect the meaning or interpretation of the terms
                  contained herein.
                </p>
                <p style={{textAlign: "justify"}}>
                  These terms shall constitute a binding contract between MF Sewa and you when you
                  download the App or use the services on the Website and/or the App or use its
                  Services on any other platform managed by MF Sewa and shall be referred to herein
                  as the "Agreement". Downloading and installing the App or using the services of
                  the Website or the App shall be deemed to constitute sufficient proof that you
                  have read, understood and accepted these terms.
                </p>
                <p style={{textAlign: "justify"}}>
                  By accessing the Website, downloading the app or the usage of the services the
                  User acknowledges to have read, understood and agrees to be legally bound by this
                  User Agreement. MF Sewa reserves the right to change the terms and conditions at
                  any time, without any notice. User’s continued use of the Website after the
                  changes to the Terms are made shall be considered as acceptance from User for the
                  changes.
                </p>
                <p style={{textAlign: "justify"}}>
                  This User Agreement contains important information regarding the Services that MF
                  Sewa will provide to you, and for your own protection, the Users must read them
                  carefully before accepting the same. If you do not agree to these terms contained
                  herein, you must not proceed to use the Website, App or use the Services provided
                  on the Website or App.
                </p>
                <p style={{textAlign: "justify"}}>
                  This User Agreement must be read in consonance with the privacy policy document or
                  other such documents as may be applicable to a particular
                  account/service/situation/circumstance/transaction.
                </p>
                <p style={{textAlign: "justify"}}>
                  Any reference to any statute, ordinance or other law includes all regulations and
                  other instruments and all consolidations, amendments, re-enactments or
                  replacements for the time being in force.
                </p>
                <p style={{textAlign: "justify"}}>
                  Without prejudice to any other specific requirement which may be laid out in this
                  User Agreement and your use of the Website and the Services and specifically, your
                  acceptance of these terms and conditions contained herein shall be deemed to be a
                  representation from you that you are above 18 years of age, or possess legal
                  parental or guardian consent, and are fully able, conscious and competent to enter
                  into this User Agreement and make your investment decisions, execute the
                  investment transactions, evaluate the advice, if any, and confirm to abide by and
                  comply with the terms set forth herein. The User agrees that any transactions
                  carried out through the Website shall be subject to the provisions contained in
                  the respective Scheme Information Documents and/or Statement of Additional
                  Information and/or Key Information Memorandum, as and when updated by relevant
                  addenda.
                </p>

                <h5 className='mt-7 mb-4'>Usage / Registration / Enrolment and Termination</h5>

                <p style={{textAlign: "justify"}}>
                  The Users shall only be allowed to avail the Services on the Website after the
                  User completes the KYC, Investment Readiness and Information Verification process
                  and provides the complete personal information in accordance with the Know Your
                  Client ("KYC") guidelines issued by the Securities and Exchange Board of India
                  ("SEBI") and/or any other regulatory / government agencies or authorities from
                  time to time.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa may use your personal information and in order to provide services to you,
                  your personal information may be processed or transferred or disclosed to and/or
                  by third parties like AMC’s, statutory bodies or agencies, or third-party service
                  providers & partners etc wherever necessary to enable us to provide better
                  services to you. With your consent, your personal information consolidated during
                  your usage of the Website / App/ services of MF Sewa may be used in order to
                  provide you with information, user experience and marketing materials in relation
                  to other products and services. By entering into this User Agreement, you agree to
                  provide your consent for your personal information to be processed for these
                  additional purposes. If you do not agree with the terms of this User Agreement, we
                  suggest you do not sign-up or avail of any Services offered by MF Sewa.
                </p>
                <p style={{textAlign: "justify"}}>
                  By registering with MF Sewa or by enrolling as its customer, either as a
                  sub-distributor or as an end investor, directly or indirectly through its
                  sub-distributors, or as an MF Sewa i-Wealth Account holder through the Website,
                  you certify that the information you may provide, now or in the future, is
                  accurate. MF Sewa reserves the right, in its sole discretion, to deny you access
                  to the Website or any portion thereof without notice for the following reasons:
                </p>

                <ul>
                  <li style={{textAlign: "justify"}}>immediately by MF Sewa for any unauthorized access or use by you.</li>
                  <li style={{textAlign: "justify"}}>
                    immediately by MF Sewa if you assign or transfer (or attempt the same) any
                    rights granted to you under this User Agreement or any Client Agreement or any
                    Sub-broker Agreement that you may have entered with MF Sewa;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    immediately, if you violate any of the other terms and conditions of this
                    Agreement The termination or cancellation of this Agreement shall not affect any
                    right or relief to which MF Sewa may be entitled to, at law or in equity. Upon
                    termination of this Agreement, all rights granted to you will terminate and
                    revert to MF Sewa. Except as set forth herein, regardless of the reason for
                    cancellation or termination of this User Agreement, the fee charged if any for
                    access to MF Sewa is non-refundable for any reason.
                  </li>
                </ul>

                <h5 className='mt-7 mb-4'>License</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa hereby grants you a limited, non-exclusive, non-assignable and
                  non-transferable license to access the Website provided and expressly conditioned
                  upon your agreement that all such access and use shall be governed by all the
                  terms and conditions set forth in this Agreement.
                </p>

                <h5 className='mt-7 mb-4'>NOT an Investment Advice</h5>

                <p style={{textAlign: "justify"}}>
                  The information on the Website is provided for information only and does not
                  constitute, and should not be construed as, investment advice or a recommendation
                  to buy, sell, or otherwise transact in any investment including any products or
                  services or an invitation, offer or solicitation to engage in any investment
                  activity. The information on the Website is provided solely on the basis that you
                  will make your own investment decisions and MF Sewa does not take account of any
                  investor’s investment objectives, particular needs, or financial situation. In
                  addition, nothing on this Site shall, or is intended to, constitute financial,
                  legal, accounting or tax advice. It is strongly recommended that you seek
                  professional investment advice before making any investment decision. Any
                  investment decision that you make should be based on an assessment of your risks
                  in consultation with your investment adviser.
                </p>

                <h5 className='mt-7 mb-4'>Investment Risks</h5>

                <p style={{textAlign: "justify"}}>
                  There are significant risks associated with an investment in any products or
                  services provided by MF Sewa. Investment in the products and services is intended
                  only for those investors who can accept the risks associated with such an
                  investment (including the risk of a complete loss of investment) and you should
                  ensure you have fully understood such risks before taking any decision to invest.
                  These Terms do not represent a complete statement of risk factors associated with
                  an investment in any of the products. The offering documentation contains risk
                  warnings which are specific to the relevant products. You should consider these
                  risk warnings carefully and take appropriate investment advice before taking any
                  decision to invest.
                </p>

                <h5 className='mt-7 mb-4'>
                  Proprietary Rights, Copyrights & no retransmission of information:
                </h5>

                <p style={{textAlign: "justify"}}>
                  All rights, title and interest in the Website and any content, as well as the
                  design and information contained in the Website contained herein, is the exclusive
                  property of MF Sewa, except as otherwise stated. MF Sewa is the sole owner of all
                  the intellectual property including all rights in trademarks, marks, logos,
                  symbols, copyright works, reports, diagrams, patents, designs created or held in
                  the Products, Services, websites etc. including but not limited to the domain
                  names, the source codes, the database etc.
                </p>

                <p style={{textAlign: "justify"}}>
                  No material /information contained on the Website constitutes or shall be
                  interpreted as investment advice, offer, solicitation or advertising with respect
                  to the purchase or sale of any security and no part of it shall form the basis of
                  or be relied upon in connection with any contract or commitment whatsoever.
                </p>

                <p style={{textAlign: "justify"}}> 
                  The Website is only for your personal and non-commercial use and for no other
                  purpose. Nothing in this Agreement shall be construed as transferring or assigning
                  any such ownership rights to you or any other person or entity. The content of the
                  Website cannot be amended, copied, reproduced, replicated, republished, uploaded,
                  posted, published, transmitted, displayed or distributed for any non-personal use
                  without obtaining prior written permission from MF Sewa. You may not perform,
                  license, frame, create derivative works from, or otherwise use in any other way
                  for commercial or public purposes in whole or in part any information, reports &
                  formats thereof, presentations of reports, software, products or services obtained
                  from the Website, except for the purposes expressly provided herein, without MF
                  Sewa's prior written approval. You may not resell, reproduce, redistribute,
                  broadcast or transfer the information or use the information in a searchable,
                  machine-readable database unless separately and specifically authorized in writing
                  by MF Sewa prior to such use. You may not rent, lease, sublicense, distribute,
                  transfer, copy, reproduce, publicly display, publish, adapt, store or time-share
                  the Website, any part or format thereof, or any of the information received or
                  accessed there-from to or through any other person or entity unless separately and
                  specifically authorized in writing by MF Sewa prior to such use. In addition, you
                  may not remove, alter or obscure any copyright, legal or proprietary notices in or
                  on any portions of the Website without prior written authorization except as set
                  forth herein, any other use of the information contained in the Website requires
                  the prior written consent of MF Sewa and the MF Sewa's logo referenced herein are
                  trademarks and service marks of MF Sewa. The names of other companies and
                  third-party products or services mentioned herein may be the trademarks or service
                  marks of their respective owners.
                </p>

                <p style={{textAlign: "justify"}}>
                  You are prohibited from using any marks for any purpose including, but not limited
                  to, use as metatags on other pages or sites on the World Wide Web without the
                  written approval of MF Sewa or such third party, which may own the marks. The
                  users of the Websites have no right to use the Intellectual Property in any manner
                  whatsoever except to the extent expressly permitted by MF Sewa. The users of the
                  Websites agree to treat as confidential and privileged all information supplied or
                  received and will not divulge or disclose the same to any third party, except to
                  those authorized by MF Sewa in writing. The users further agree not to use any
                  such information to the detriment of MF Sewa any other entity in any way
                  whatsoever, at any time. The users agree not to make copies of any such
                  confidential information or any part thereof except for their personal consumption
                  within the terms of this agreement. MF Sewa reserves the right to terminate the
                  accounts of Users / Customers / Partners, who violate the proprietary rights. In
                  addition, in case of any act in actual or intentional breach of these terms,
                  directly or indirectly, by any means or modes, you will be liable for actual and
                  punitive damages as determined by MF Sewa and additional damages to be determined
                  by an Indian court of Law.
                </p>

                <h5 className='mt-7 mb-4'>Security</h5>

                <p style={{textAlign: "justify"}}>
                  Unauthorized use of MF Sewa's Website and systems, including but not limited to,
                  unauthorized entry into MF Sewa's systems, online accounts, MF Sewa i-Wealth
                  Account, MF Sewa Wealth Partner /Distributor Desks, MF Sewa Agency Desks, etc., or
                  the misuse of passwords or misuse of any other information, is strictly
                  prohibited. You may not use the Website in any manner that could damage, disable,
                  overburden, or impair any MF Sewa's site or service or interfere with any other
                  party's use and enjoyment of any MF Sewa's site, service or online accounts. You
                  may not attempt to gain unauthorized access to any of our site or service and
                  computer systems or networks connected to it, through hacking. You agree that you
                  will not engage in any activities related to the Website that are contrary to the
                  applicable laws or regulations in force.
                </p>

                <p style={{textAlign: "justify"}}>
                  The users of online accounts/desks including MF Sewa i-Wealth Account, Partners
                  Desk have the primary responsibility to keep their login-ids and passwords secure
                  and to not display such crucial, important information to any third person. In
                  case of any such leak of login-id & password, MF Sewa shall not be held
                  responsible for the loss of such information, or for any act of omission or
                  commission or for any consequences, damages, claims, loss of personal information
                  or for any action, transaction, instruction, etc. w.r.t. to any security,
                  investment product or otherwise, or any alteration in personal information or
                  admin rights, etc., arising from the use of such leakage of login-ids and
                  passwords.
                </p>

                <p style={{textAlign: "justify"}}>
                  MF Sewa may use certain technology on its websites to collect information from
                  visitors and may compile aggregate statistical information about how visitors use
                  its websites, including information relating to the frequency of visits, the
                  average length of visits, which pages are viewed during a visit. MF Sewa uses this
                  information to improve its website content and performance. No individually
                  identifiable information is collected for this purpose, and MF Sewa does not
                  monitor individual visitor behaviour. MF Sewa does not represent or warrant that
                  the Website will be available and meet your requirements, that access will not be
                  interrupted, that there will be no delays, failures, errors or omissions or loss
                  of transmitted information, that no viruses or other contaminating or destructive
                  properties will be transmitted or that no damage will occur to your computer
                  system. You have sole responsibility for adequate protection and back up of data
                  and/or equipment and for undertaking reasonable and appropriate precautions to
                  scan for computer viruses or other destructive properties. We make no
                  representations or warranties regarding the accuracy, functionality or performance
                  of any third-party software that may be used in connection with the Website.
                </p>

                <p style={{textAlign: "justify"}}>
                  MF Sewa/MF Sewa Wealth Partners do not provide and are also not authorized to
                  provide any opinion, recommendations, analysis, etc. on any client's consolidated
                  or multi-product portfolio, except limited to only mutual funds. However, clients
                  may be presented with consolidated of their investments, financial holdings,
                  including but not limited to the details of current holdings, historical data,
                  profit & loss info, returns & performance details, key observations/highlights,
                  consolidated asset allocation, account info, other factual representations, either
                  in summary, detailed in any suitable format, etc, which should not be interpreted
                  as investment advice whatsoever.
                </p>

                <h5 className='mt-7 mb-4'>General Information</h5>

                <p style={{textAlign: "justify"}}>
                  All material on the Website accessible without the use of any login, including but
                  not limited to all information, online tools, articles, publications, literature,
                  interviews, mutual fund reports, third-party reports, research insights, etc. are
                  freely available to the public. Nothing shall be construed as investment advice or
                  any client-specific recommendations. Please note that the views expressed, if any,
                  in any of these materials on the Website, are to be interpreted as general
                  comments in good faith in regard to trends in the financial or securities market
                  or the economic situation and such views are not specifically in the context of
                  any particular security or investment product and hence, such communication should
                  not be treated as investment advice. MF Sewa shall not be held responsible for any
                  views, personal opinions or data shared by third-parties being interviewed /quoted
                  and published in any format on the Website and that the same shall be considered
                  as personal views/opinion of such third-parties. Further, MF Sewa does not claim
                  responsibility for reports, publications released by third parties and MF Sewa's
                  role is limited to only serve as a platform to share them with the public in good
                  faith.
                </p>

                <h5 className='mt-7 mb-4'>Service Delays</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa reserves its right, in its sole discretion, without any obligation and
                  without any notice requirement, to change, improve or correct the information,
                  materials and descriptions on the Website and to suspend and/or deny access to the
                  Website for scheduled or unscheduled maintenance, upgrades, improvements or
                  corrections. MF Sewa may discontinue or change any product or service or online
                  user accounts described in or offered on its website or in any modules or parts
                  thereof at any time if deemed fit and proper. MF Sewa (including its directors,
                  employees, affiliates, agents, representatives or subcontractors) shall not be
                  liable for any loss or liability resulting, directly or indirectly, from delays or
                  interruptions due to electronic or mechanical equipment failures, telephone
                  internet connection problems, pandemic, defects, weather, strikes, walkouts, fire,
                  riots, armed conflicts, acts of war, or other like causes. MF Sewa shall have no
                  responsibility to provide you access to the Website while interruption of the
                  Website is due to any such cause as mentioned above shall continue.
                </p>

                <h5 className='mt-7 mb-4'>Liability Disclaimer:</h5>

                <p style={{textAlign: "justify"}}>
                  YOU EXPRESSLY AGREE THAT THE USE OF THE WEBSITE IS AT YOUR SOLE RISK The
                  information and materials on the Website may contain typographical errors or
                  inaccuracies. Any dated information is published as of its date only with due
                  care, and MF Sewa does not undertake any obligation or responsibility to update or
                  amend any such information. The information, products and services on this Website
                  are provided on an "AS IS, WITH ALL FAULTS", "WHERE IS" and "WHERE AVAILABLE"
                  basis. MF Sewa does not warrant the information or services provided herein or
                  your use of this Website generally, either expressly or impliedly, for any
                  particular purpose and expressly disclaims any implied warranties, including but
                  not limited to, warranties of title, non-infringement, merchantability or fitness
                  for a particular purpose. MF Sewa will not be responsible for any loss or damage
                  that could result from interception by third parties of any information or
                  services made available to you on this Website. Although the information provided
                  to you on this Website is obtained or compiled from sources, we believe to be
                  reliable, MF Sewa cannot and does not guarantee the accuracy, validity, timeliness
                  or completeness of any information or data made available to you for any
                  particular purpose. Neither MF Sewa, nor any of its affiliates, directors,
                  officers or employees, nor any third party vendor, or associates, groups, Partners
                  or other sub-brokers, will be liable or have any responsibility of any kind for
                  any loss or damage or compensation that you may incur in the event of any failure
                  or interruption of this Website, or resulting from the act or omission of any
                  other party involved in making this Website, the data contained herein or the
                  products or services offered on this Website available to you, or from any other
                  cause relating to your access to, inability to access, or use of the Website these
                  materials, whether the circumstances giving rise to such cause may have been
                  within the control of MF Sewa or of any vendor providing software or services.
                </p>

                <p style={{textAlign: "justify"}}>
                  Under this Agreement, you assume all risk of errors and/or omissions in this
                  Website, including the transmission or translation of information. You assume full
                  responsibility for implementing sufficient procedures and checks to satisfy your
                  requirements for the accuracy and suitability of this Website, including the
                  information, and for maintaining any means, which you may require, for the
                  reconstruction of lost data or subsequent manipulations or analyses of the
                  information under the User Agreement.
                </p>

                <p style={{textAlign: "justify"}}>
                  YOU AGREE THAT MF Sewa (Including its and their officers, directors, employees,
                  affiliates, group companies agents, representatives or subcontractors) shall not,
                  in any event, be liable, whether in contract or tort, for any direct, special,
                  indirect, consequential or incidental damages or other damages of any kind arising
                  out of the use or inability to use the Website for any purpose whatsoever even if
                  MF Sewa or any other such party has been advised of the possibility thereof. MF
                  Sewa and its affiliates, officers, directors, employees and agents shall have no
                  liability in tort, contract, or otherwise to the user and/or any third party. This
                  limitation on liability includes, but is not limited to, the transmission of any
                  viruses which may infect a user's equipment, failure of mechanical or electronic
                  equipment or communication lines, telephone or other interconnect problems (e.g.,
                  you cannot access your internet service provider), unauthorized access, theft,
                  operator errors, strikes or other labour problems or any force majeure. MF Sewa
                  cannot and does not guarantee continuous, uninterrupted or secure access to this
                  Website.
                </p>

                <p style={{textAlign: "justify"}}>
                  No warranty is given that the contents of the Website are compatible with all
                  computer systems or browsers or that this Website shall be available on an
                  uninterrupted basis. MF Sewa does not accept any liability for any damages or
                  losses arising from changes or alterations made to this Website by unauthorized
                  third parties. The internet is not a completely reliable transmission medium and
                  neither MF Sewa nor any of its affiliates accept any liability for any data
                  transmission errors such as data loss or damage or alteration of any kind or for
                  the security or confidentiality of information transmitted across the internet to
                  or from MF Sewa or any of its affiliates. Any such transmission of information is
                  entirely at your own risk and any material downloaded from this Website is
                  downloaded at your own risk. The information on this Site is provided “as is”. To
                  the extent permitted by law, MF Sewa, its affiliates and each of their directors,
                  officers, employees and agents expressly exclude all conditions, warranties,
                  representations and other terms which might otherwise be implied by statute,
                  common law or the law of equity. In no event will MF Sewa or any of its affiliates
                  be liable to any person for any indirect, special or consequential damages arising
                  out of any use of, or inability to use, this Site or the information contained on
                  it or on any other hyper-linked site including, without limitation, any lost
                  profits, business interruption, loss of programs or data on your equipment or
                  otherwise, even if MF Sewa is expressly advised of the possibility or likelihood
                  of such damages. This does not affect the liability of the MF Sewa and its
                  affiliates for any loss or damage which cannot be excluded or limited under
                  applicable law.
                </p>

                <h5 className='mt-7 mb-4'>Links to third party sites</h5>

                <p style={{textAlign: "justify"}}>
                  The links in the Website will allow you to leave the Website to third-party
                  websites. The linked websites are not under the control of MF Sewa. MF Sewa has
                  neither reviewed nor approved these websites and is not responsible for the
                  contents or omissions of any linked site or any links contained in a linked site.
                  The inclusion of any linked website does not imply endorsement by MF Sewa of the
                  website.
                </p>

                <h5 className='mt-7 mb-4'>Indemnification</h5>

                <p style={{textAlign: "justify"}}>
                  YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS MF Sewa (including its and their
                  officers, directors, employees, affiliates, group companies, agents,
                  representatives or subcontractors) from any and all claims and losses imposed on,
                  incurred by or asserted as a result of or related to: (a) your access and use of
                  this Website (b) any non-compliance by user with the terms and conditions hereof;
                  or (c) any third party actions related to users receipt and use of the
                  information, whether authorized or unauthorized. Any clause declared invalid shall
                  be deemed severable and not affect the validity or enforceability of the
                  remainder. These terms may only be amended in a writing signed by MF Sewa. If MF
                  Sewa takes action (by itself or through its associate companies) to enforce any of
                  the provisions of this Agreement, including the collection of any amounts due
                  hereunder, MF Sewa shall be entitled to recover from you (and you agree to pay),
                  in addition to all sums to which it is entitled or any other relief, at law or in
                  equity, reasonable and necessary attorney's fees and any costs of any litigation.
                </p>

                <h5 className='mt-7 mb-4'>Force Majeure:</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa (including its directors, employees, affiliates, agents, representatives
                  or subcontractors) shall not be liable if any transaction does not fructify or may
                  not be completed or for any failure on part of MF Sewa to perform any of its
                  obligations under or those applicable specifically to its services/facilities if
                  performance is prevented, hindered or delayed by a Force Majeure Event and in such
                  case its obligations shall be suspended for so long as the Force Majeure Event
                  continues. "Force Majeure Event" means any event due to any cause beyond the
                  reasonable control of MF Sewa, including without limitations, unavailability of
                  any communication systems, breach, or virus in the processes or payment or
                  delivery mechanism, sabotage, fire, flood, explosion, acts of god, civil
                  commotion, strikes or industrial action of any kind, riots, insurrection, war,
                  acts of government, computer hacking, unauthorized access to computer data and
                  storage devices, computer crashes, malfunctioning in the computer terminal or the
                  systems getting affected by any malicious, destructive or corrupting code or
                  program, mechanical or technical errors/failures or power shut down, faults or
                  failures in telecommunication etc.
                </p>

                <h5 className='mt-7 mb-4'>Entire Agreement:</h5>

                <p style={{textAlign: "justify"}}>
                  This Agreement constitutes the entire agreement between the parties and is
                  inclusive of any other written agreement existing between you and MF Sewa. By
                  using the information on this Website, you assume full responsibility for any and
                  all gains and losses, financial, emotional or otherwise, experienced, suffered or
                  incurred by you. MF Sewa does not guarantee the accuracy, completeness or
                  timeliness of, or otherwise endorse in any way, the views, opinions or
                  recommendations expressed in the Information, does not give investment advice and
                  does not advocate the purchase or sale of any security or investment by you or any
                  other individual. The Information is not intended to provide tax, legal or
                  investment advice, which you should obtain from your professional advisor prior to
                  making any investment of the type discussed in the Information. The Information
                  does not constitute a solicitation by the information providers, MF Sewa or other
                  of the purchase or sale of securities.
                </p>

                <p style={{textAlign: "justify"}}>
                  THE SERVICE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                  IMPLIED, INCLUDING WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA,
                  SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH
                  THE SERVICE. SPECIFICALLY, MF SEWA DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING,
                  BUT NOT LIMITED TO: (i) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
                  USEFULNESS, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES; AND (ii) ANY
                  WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY
                  OR FITNESS FOR A PARTICULAR PURPOSE. THIS DISCLAIMER OF LIABILITY APPLIES TO ANY
                  DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
                  INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
                  VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO,
                  ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORT, NEGLIGENCE,
                  OR UNDER ANY OTHER CAUSE OF ACTION. NEITHER MF SEWA NOR ANY OF ITS EMPLOYEES,
                  AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, GROUP COMPANIES OR CONTENT OR SERVICE
                  PROVIDERS SHALL BE LIABLE TO YOU OR OTHER THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF USE OF SERVICE OR
                  INABILITY TO GAIN ACCESS TO OR USE THE SERVICE OR OUT OF ANY BREACH OF ANY
                  WARRANTY. BECAUSE SOME COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT
                  APPLY TO YOU. IN SUCH COUNTRIES, THE RESPECTIVE LIABILITY OF MF SEWA, ITS
                  EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, GROUP COMPANIES AND CONTENT OR
                  SERVICE PROVIDERS RESPECTIVE LIABILITY IS LIMITED TO THE AMOUNT PROVIDED UNDER
                  SAID LAW. FURTHER, YOU AGREE AND UNDERSTAND THAT ALL SERVICES PROVIDED ARE
                  NON-REFUNDABLE AND THAT YOU SHOULD CAREFULLY CONSIDER WHETHER OUR SERVICES ARE
                  ABLE TO MEET YOUR NEEDS. Transactions between users of the Website and MF Sewa
                  shall be governed by and construed in accordance with the SEBI Regulations and
                  other applicable laws of India, without regard to the laws regarding conflicts of
                  law. Any litigation regarding this agreement or any transaction between customer
                  and MF Sewa or any action at law or in equity arising out of or relating to this
                  agreement or transaction shall be filed only in the Competent Courts of Surat and
                  the customer hereby acknowledges, agrees, consents and submits to the jurisdiction
                  of such courts for the purpose of litigating any such action.
                </p>

                <h5 className='mt-7 mb-4'>Change of Terms and Conditions</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa has the absolute discretion to amend or supplement any of the terms of the
                  Website at any time. MF Sewa may introduce new services or withdraw any of the
                  existing services, whenever considered necessary. The existence and availability
                  of the new functions will be notified to the User as and when the same become
                  available. By using these new services, the User agrees to be bound by the
                  applicable terms and conditions. The User shall be responsible for regularly
                  reviewing these terms including amendments thereto as may be posted on the
                  Website. If in the opinion of the User, the changes are to their disadvantage, the
                  Customer may at its discretion opt to suspend the services provided by MF Sewa.
                </p>

                <h5 className='mt-7 mb-4'>Jurisdiction</h5>

                <p style={{textAlign: "justify"}}>
                  The usage of the Website and the terms of this User Agreement are exclusively
                  based on and subject to Indian laws. The User hereby consents to the exclusive
                  jurisdiction and venue of courts in Surat, Gujarat, India in all disputes arising
                  out of or relating to the use of the Website. Use of the Website is unauthorized
                  in any jurisdiction that does not give effect to all provisions of these terms and
                  conditions, including without limitation this paragraph.
                </p>

                <div className='d-flex w-100 justify-content-around align-items-center my-4'>
                  <span>
                    {' '}
                    <a href='https://www.sebi.gov.in/' target={'_blank'}> SEBI </a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.rbi.org.in/' target={'_blank'}> RBI </a>
                  </span>
                  <span>
                    {' '}
                    <a href='http://www.bseindia.com/' target={'_blank'}> BSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nseindia.com/' target={'_blank'}> NSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.cdslindia.com/' target={'_blank'}> CDSL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nsdl.co.in/' target={'_blank'}> NSDL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.amfiindia.com/' target={'_blank'}> AMFI</a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.icexindia.com/' target={'_blank'}> ICEX</a>{' '}
                  </span>
                </div>

                <h5 className='mt-7'>Attention Investors:</h5>
                <ol>
                  <li style={{textAlign: "justify"}}>
                    "Prevent Unauthorized transactions in your Trading/Demat Account. Update your
                    mobile numbers/email IDs with your Stock Brokers/Depository Participant. Receive
                    alerts/information of your transaction/all debit and other important
                    transactions in your Trading/ Demat Account directly from Exchange/NSDL/CDSL at
                    the end of the day. Issued in the interest of investors."
                  </li>
                  <li style={{textAlign: "justify"}}>
                    "KYC is one time exercise while dealing in securities markets - once KYC is done
                    through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need
                    not undergo the same process again when you approach another intermediary."
                  </li>
                  <li style={{textAlign: "justify"}} >
                    "No need to issue cheques by investors while subscribing to IPO. Just write the
                    bank account number and sign in the application form to authorize your bank to
                    make payment in case of allotment. No worries for refund as the money remains in
                    investor's account.".
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Check your Securities /MF/ Bonds in the consolidated account statement issued by
                    NSDL/CDSL every month.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Mutual Fund investments are subject to market risk, read all scheme related
                    documents carefully.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    In case of any grievances against any listed company, member of stock exchanges
                    and Depository Participant, please lodge the same through online mechanisms more
                    specifically through SEBI SCORES portal and SEBI SCORES mobile application for
                    effective redressal of grievances. Furthermore, you can also lodge online
                    complaints / grievances directly with NSDL/CDSL/depository participants through
                    the link / option available on their website.
                  </li>
                </ol>
              </div>
              {/* <div className='col-md-6 position-relative ps-2'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
                  className='w-75 position-absolute top-50 start-50 translate-middle .img-fluid'
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Terms}
