import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './UserReportHelper'
import {useNavigate} from 'react-router-dom'
import {getAuth} from '../../../auth/core/AuthHelpers'
import axios, {AxiosResponse} from 'axios'
import {useAuth} from '../../../auth'
import {UsersQueryResponse} from '../../user-management/users-list/core/_models'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments`
const GET_VALUE_REPORT = `${API_URL}/customer/get_overall_report`

const config = {
  headers: {
    Content_type: 'application/json',
    'x-access-token': auth ? auth.data?.token : '',
  },
}

const getUserReport = (customerId: any): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_VALUE_REPORT}?userId=${customerId}`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getCustomerDetails = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_INVESTMENT_URL}?page=1&item_per_page=5000&userId='${auth?.data.id}'`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const ValuationReportPage: FC<any> = ({setReportType}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [allCustomerDetails, setCustomerDetails] = useState<any[]>([])
  const [userReport, setUserReport] = useState<any[]>([])
  const [customerId, setCustomerId] = useState<any>()

  const currentUser = useAuth()

  let navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    console.log(values)
    if (values.customerSelect !== '') {
      setCustomerId(values.customerSelect)
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
      customerId &&
        (currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-customer-report/' + customerId, {
              state: {userReport, customerId: customerId, filters: values},
            })
          : navigate('/reports/current-holdings/show', {
              state: {userReport, customerId: customerId, filters: values},
            }))
    }
  }

  useEffect(() => {
    currentUser.currentUser?.roleId !== 1 && setCustomerId(auth?.data.id)
    // customerId &&
    // getUserReport(customerId,asOnDate).then((d: any) => {
    //   setUserReport(d)
    // })
  }, [customerId])

  useEffect(() => {
    getCustomerDetails().then((d: any) => setCustomerDetails(d.data))
    if (currentUser.currentUser?.roleId !== 1) {
      setSubmitButton(true)
      setCurrentSchema(createAccountSchemas[createAccountSchemas.length - 1])
    }
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      {currentUser.currentUser?.roleId == 1 ? (
        <button
          className='btn btn-light'
          onClick={() => {
            setReportType('')
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
      ) : (
        <>
          <PageTitle breadcrumbs={usersBreadcrumbs}>Current Holdings</PageTitle>
          <button
            className='btn btn-light'
            onClick={() => {
              navigate('/reports')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
            Back
          </button>
        </>
      )}

      <div className='card'>
        <div className='card-body'>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column pt-15'
            id='kt_create_account_stepper'
          >
            <div className='stepper-nav mb-5'>
              {currentUser.currentUser?.roleId === 1 ? (
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Choose User</h3>
                </div>
              ) : (
                ''
              )}

              <div
                className={`stepper-item ${currentUser.currentUser?.roleId !== 1 ? 'current' : ''}`}
                data-kt-stepper-element='nav'
              >
                <h3 className='stepper-title'>Select Actions</h3>
              </div>
            </div>

            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {() => (
                <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='choose_fund_type_form'>
                  {currentUser.currentUser?.roleId === 1 ? (
                    <>
                      <div className='current' data-kt-stepper-element='content'>
                        <Step1 allCustomerDetails={allCustomerDetails} />
                      </div>
                      <div data-kt-stepper-element='content'>
                        <Step2 customerId={customerId != '' ? customerId : ''} />
                      </div>
                    </>
                  ) : (
                    <div className='current' data-kt-stepper-element='content'>
                      <Step2 customerId={auth?.data.id} />
                    </div>
                  )}

                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isSubmitButton && 'Continue'}
                          {isSubmitButton && 'Submit'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export {ValuationReportPage}
