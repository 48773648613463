import React, {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {PageTitle} from '../../../_metronic/layout/core'

const Privacy: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Privacy Policy</PageTitle>
      <div className='content flex-column-fluid' id='kt_content'>
        <div className='card'>
          <div className='card-body p-lg-17'>
            <div className='row mb-3 d-flex justify-content-evenly'>
              <div className='col-md-12 pe-lg-10'>
                <h2 className='text-center mb-6'>Privacy Policy</h2>

                <h5 className='mb-4'>Our Commitment</h5>
                <p style={{textAlign: "justify"}}>
                  MF Sewa Kendra Private Limited is a mutual fund distributor (ARN 106884)
                  registered with AMFI, trading member and depository participant and is also
                  engaged in providing other financial and related services. To enable this, MF Sewa
                  would need to complete your Know Your Clients (KYC) in accordance with regulatory
                  / government and legal agencies and authorities; your information may be
                  provided/verified or updated in this process with respective regulatory agencies
                  as per the regulations applicable at that time. For the purpose of execution of
                  investments, your information may be provided to respective Asset Management
                  Companies, KYC Registration Agencies, Central KYC Registry and other statutory
                  authorities in accordance with the regulations and applicable laws. You understand
                  and acknowledge that whenever information is provided to such third parties, it
                  shall be bound by the terms and conditions & privacy policy of such third-party
                  service providers/agencies.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa is governed by the provisions of applicable laws in India including the
                  Information Technology Act 2000 and the Information Technology (Reasonable
                  Security Practices and Procedures and Sensitive Personal Data or Information)
                  Rules, 2011 to maintain the privacy of your Personal Data.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa collects and generates various data points about you. Data by which you
                  are identified is termed as “Personal Data”. Personal Data does not mean
                  information that is freely available or accessible in the public domain. Your
                  privacy is of utmost importance to MF Sewa and the protection of your Personal
                  Data is a key commitment for MF Sewa.
                </p>

                <h5 className='mt-7 mb-4'>Information collected by MF Sewa</h5>
                <p style={{textAlign: "justify"}}>
                  Personal Data that MF Sewa may collect from you includes, but are not limited to,
                  the following:
                </p>

                <ul>
                  <li style={{textAlign: "justify"}}>
                    Personal details (e.g. name, contact details including, residential address,
                    date of birth, documents such as identity card/passport details / Aadhaar
                    details / PAN / Voter ID/driving license, and/or education details) provided by
                    you to MF Sewa to avail various services from MF Sewa;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Your details including transaction history, balances and payment details, for
                    effecting transfer of monies through various payment channels facilitated by MF
                    Sewa;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Financial details (e.g. income, expenses, and/or credit history) needed as part
                    of the request for some of MF Sewa’s products/services;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Images/videos of documents/photos required to avail any of our
                    products/services;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Voice recordings of conversations with MF Sewa’s customer care agent by you to
                    address your queries/grievances;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Risk profile, investment selection, beneficiary and nominee information etc.;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Employment details (e.g. occupation, employer name, positions held, employment
                    history, salary and/or benefits) as part of MF Sewa’s record retention for
                    credit / various product evaluations or as required under applicable law
                    including Prevention of Money Laundering (Maintenance of Records) Rules, 2005;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Specimen signature(s) or consent for the processing of your instructions
                    received by MF Sewa through our various payment and delivery channels;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Opinions provided by you to MF Sewa by way of feedback or responses to surveys.
                  </li>
                </ul>

                <h5 className='mt-7 mb-4'>Usage of Your Personal Data:</h5>

                <p style={{textAlign: "justify"}}>
                  Subject to applicable laws, MF Sewa may use your Personal Data in its business
                  operations for providing its or its partners’ products services and to perform,
                  among other actions, the following:
                </p>

                <ul>
                  <li style={{textAlign: "justify"}}>To facilitate the transactions or report on these transactions;</li>
                  <li>
                    To check and process your requests submitted to MF Sewa for products/services
                    and/or instructions or requests received from you in respect of these
                    products/services;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    To share with you, updates on changes to the products/services and their terms
                    and conditions;
                  </li>
                  <li style={{textAlign: "justify"}}>To take up or investigate any complaints/claims/disputes;</li>
                  <li style={{textAlign: "justify"}}>To respond to your queries or feedback submitted by you;</li>
                  <li style={{textAlign: "justify"}}>To verify your identity to provide products/services to you;</li>
                  <li style={{textAlign: "justify"}}>
                    To carry credit checks, screenings or due diligence checks as lawfully required
                    by us;
                  </li>
                  <li style={{textAlign: "justify"}}>To monitor and review services from time to time;</li>
                  <li style={{textAlign: "justify"}}>
                    To undertake financial/regulatory / management reporting, and create and
                    maintain various risk management models;
                  </li>
                  <li style={{textAlign: "justify"}}>For conducting audits and for record-keeping purposes;</li>
                  <li style={{textAlign: "justify"}}>For selective offers and promotions.</li>
                </ul>

                <p style={{textAlign: "justify"}}>
                  MF Sewa also uses your Personal Data to fulfil the requirements of applicable
                  laws/regulations and/or court orders / regulatory directives received by MF Sewa.
                </p>

                <h5 className='mt-7 mb-4'> Other Websites</h5>

                <p style={{textAlign: "justify"}}>
                  The Website and its associate/group sites contain links to other websites. MF Sewa
                  is not responsible for the privacy practices or the content of such third-party
                  websites.
                </p>

                <h5 className='mt-7 mb-4'>Sharing of Data</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa may share any Personal Data of clients/investors with the following
                  parties, in connection with and as may be necessary for the conduct of MF Sewa’s
                  business/operations:
                </p>

                <ol>
                  <li style={{textAlign: "justify"}}>
                    With the Registrar & Transfer Agent/s (RTA) of various Mutual Funds and/or other
                    issuers, group companies and associate entities to facilitate the services,
                    banks and/or authorised external third parties/intermediaries or any other
                    service provider who are involved in transaction processing, dispatches,
                    printing/marketing etc. of clients/investors investment in any products, unless
                    any investor/unitholder has specifically written to MF Sewa to refrain from
                    sharing such information with such parties;
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Any regulatory/administrative/legislative authority and/or with the MF Sewa for
                    compliance with any legal, statutory or regulatory requirements and/or to verify
                    the identity of clients/investors for complying with anti-money laundering/KYC
                    requirements and/or under any applicable laws for the time being in force and/or
                    for the purpose of data storage.
                  </li>
                </ol>

                <p style={{textAlign: "justify"}}>
                  MF Sewa MF Sewa shares such aforesaid information to the aforesaid service
                  providers under strict confidentiality and only on a 'need to know basis and for
                  the purposes of lawful functioning of MF Sewa’s business/operations. MF Sewa sends
                  the account statements or financial information pertaining to the
                  clients/investors to be sent over the internet and only through a secure mode. MF
                  Sewa also ensures that the parties to whom such aforesaid information/data is
                  shared have confidentiality provisions and high levels of security practices,
                  controls and procedures to ensure its protection.
                </p>
                <p style={{textAlign: "justify"}}>
                  Notwithstanding anything contained, MF Sewa shall not be responsible/liable,
                  either directly or indirectly, for the authenticity/accuracy of the
                  personal/financial information or any other data/information submitted by you
                  and/or for its misuse, not attributable to the acts of MF Sewa, at any time and in
                  any manner whatsoever. In case you feel that any information/data provided by you
                  is inaccurate/deficient or requires updation, then you shall ensure to
                  correct/amend such information/data as soon as possible by getting in touch with
                  MF Sewa. MF Sewa will at all times endeavor to handle transactions efficiently and
                  to resolve any investor grievances promptly.
                </p>
                <p style={{textAlign: "justify"}}>
                  MF Sewa reserves the right to modify sections of this Privacy Policy at any time
                  without giving any prior notice. Please check this Policy from time to time for
                  any changes.
                </p>

                <h5 className='mt-7 mb-4'>Reasonable Security Practices and Procedures</h5>

                <p style={{textAlign: "justify"}}>
                  MF Sewa takes various steps and measures to protect the security of your Personal
                  Data from misuse, loss, unauthorized access, modification or disclosure. MF Sewa
                  uses the latest secured server layers encryption and access control on its
                  systems. MF Sewa safety and security processes are audited by a third-party
                  cybersecurity audit agency from time to time.
                </p>

                <p style={{textAlign: "justify"}}>
                  MF Sewa has provided multiple levels of security to safeguard your app by the
                  Login / Logout option. MF Sewa also ensures the device binding so that the same
                  login cannot be used on different devices without any additional authentication /
                  OTP. Please do not share your MF Sewa login, password and OTP details with
                  anybody.
                </p>

                <h5 className='mt-7 mb-4'>Notify Us</h5>

                <p style={{textAlign: "justify"}}>
                  If at any time you believe that MF Sewa or its users/members have not adhered to
                  these principles, please notify MF Sewa by e-mail at{' '}
                  <a href='mailto:feedback@mfsewa.in'>feedback@mfsewa.in</a> and MF Sewa will use
                  all commercially reasonable efforts to promptly determine and correct the problem.
                </p>

                <div className='d-flex w-100 justify-content-around align-items-center my-4'>
                  <span>
                    {' '}
                    <a href='https://www.sebi.gov.in/' target={'_blank'}> SEBI </a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.rbi.org.in/' target={'_blank'}> RBI </a>
                  </span>
                  <span>
                    {' '}
                    <a href='http://www.bseindia.com/' target={'_blank'}> BSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nseindia.com/' target={'_blank'}> NSE</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.cdslindia.com/' target={'_blank'}> CDSL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.nsdl.co.in/' target={'_blank'}> NSDL</a>
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.amfiindia.com/' target={'_blank'}> AMFI</a>{' '}
                  </span>
                  <span>
                    {' '}
                    <a href='https://www.icexindia.com/' target={'_blank'}> ICEX</a>{' '}
                  </span>
                </div>

                <h5 className='mt-7'>Attention Investors:</h5>
                <ol>
                  <li style={{textAlign: "justify"}}>
                    "Prevent Unauthorized transactions in your Trading/Demat Account. Update your
                    mobile numbers/email IDs with your Stock Brokers/Depository Participant. Receive
                    alerts/information of your transaction/all debit and other important
                    transactions in your Trading/ Demat Account directly from Exchange/NSDL/CDSL at
                    the end of the day. Issued in the interest of investors."
                  </li>
                  <li style={{textAlign: "justify"}}>
                    "KYC is one time exercise while dealing in securities markets - once KYC is done
                    through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need
                    not undergo the same process again when you approach another intermediary."
                  </li>
                  <li style={{textAlign: "justify"}}>
                    "No need to issue cheques by investors while subscribing to IPO. Just write the
                    bank account number and sign in the application form to authorize your bank to
                    make payment in case of allotment. No worries for refund as the money remains in
                    investor's account.".
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Check your Securities /MF/ Bonds in the consolidated account statement issued by
                    NSDL/CDSL every month.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    Mutual Fund investments are subject to market risk, read all scheme related
                    documents carefully.
                  </li>
                  <li style={{textAlign: "justify"}}>
                    In case of any grievances against any listed company, member of stock exchanges
                    and Depository Participant, please lodge the same through online mechanisms more
                    specifically through SEBI SCORES portal and SEBI SCORES mobile application for
                    effective redressal of grievances. Furthermore, you can also lodge online
                    complaints / grievances directly with NSDL/CDSL/depository participants through
                    the link / option available on their website.
                  </li>
                </ol>
              </div>
              {/* <div className='col-md-6 position-relative ps-2'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
                  className='w-75 position-absolute top-50 start-50 translate-middle .img-fluid'
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Privacy}
