import * as Yup from 'yup'

export interface ICreateAccount {
  customerSelect: string
  displaySIP: string
  folioStatus: string
  status: string
  columns: string
  // asOnDate: string
}

const createAccountSchemas = [
  Yup.object({
    customerSelect: Yup.string()
      .required('Please select a user to proceed')
      .label('Customer Select')
      .notOneOf(['', null, undefined]),
  }),
  Yup.object().shape({
    displaySIP: Yup.string().label('Display SIP'),
    folioStatus: Yup.string().label('Folio Status'),
    status: Yup.string().label('Status'),
    columns: Yup.string().label('Columns'),
    // asOnDate: Yup.string().label('As on Date'),
  }),
]

const inits: ICreateAccount = {
  customerSelect: '',
  displaySIP: 'all',
  folioStatus: 'all',
  status: '',
  columns: '',
  // asOnDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
  //   .toISOString()
  //   .split('T')[0],
}

export {createAccountSchemas, inits}
