import * as Yup from 'yup'

export interface ICreateAccount {
  customerSelect: string
  type: string
  subType: string
  nature: string
  columns: string
  period: string
  // asOnDate: string
}

const createAccountSchemas = [
  Yup.object({
    customerSelect: Yup.string()
      .required('Please select a user to proceed')
      .label('Customer Select')
      .notOneOf(['', null, undefined]),
  }),
  Yup.object().shape({
    type: Yup.string().label('Type'),
    subType: Yup.string().label('Sub Type'),
    nature: Yup.string().label('TransactionNature'),
    columns: Yup.string().label('Columns'),
    period: Yup.string().label('Period'),
    // asOnDate: Yup.string().label('As on Date'),
  }),
]

const inits: ICreateAccount = {
  customerSelect: '',
  type: '',
  subType: '',
  nature: '',
  columns: '',
  period: '',

  // asOnDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
  //   .toISOString()
  //   .split('T')[0],
}

export {createAccountSchemas, inits}
