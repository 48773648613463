import * as Yup from 'yup'

export interface ICreateAccount {
  customerSelect: string
  type: string[]
  subType: string[]
  amc: string[]
  scheme: string[]
  asOnDate: string
}

const createAccountSchemas = [
  Yup.object({
    customerSelect: Yup.string()
      .required('Please select a user to proceed')
      .label('Customer Select')
      .notOneOf(['', null, undefined]),
  }),
  Yup.object().shape({
    type: Yup.array().of(Yup.string()).label('Type'),
    subType: Yup.array().of(Yup.string()).label('Sub Type'),
    amc: Yup.array().of(Yup.string()).label('AMC'),
    scheme: Yup.array().of(Yup.string()).label('Scheme'),
    asOnDate: Yup.string().label('As on Date'),
  }),
]

const inits: ICreateAccount = {
  customerSelect: '',
  type: [],
  subType: [],
  amc: [],
  scheme: [],
  asOnDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
    .toISOString()
    .split('T')[0],
}

export {createAccountSchemas, inits}
