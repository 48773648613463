/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {
  User,
  UsersQueryResponse,
} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import {getAuth} from '../../../../../src/app/modules/auth/core/AuthHelpers'
const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const UPDATE_INVESTMENT = `${API_URL}/customer/update_investment`

export const Dropdown4 = ({item, triggerOne, setTriggerOne}: any) => {
  // const [triggerOne, setTriggerOne] = useState(false)

  const applyData = (id: number) => {
    const a: any = document.getElementById(`status${id}`)
    //setTableItem(id, 'status', a?.value)
    const b: any = document.getElementById(`note${id}`)
    if (b) {
      // setTableItem(id, 'note', b?.value)
    }
    const payload = new FormData()
    payload.append('status', a?.value)
    console.log(item)
    setTriggerOne(!triggerOne)
    // console.log("Hello world!",id);

    return axios
      .put(`${UPDATE_INVESTMENT}/${item.id}`, {status: a?.value})
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const resetData = (id: number) => {
    const a: any = document.getElementById(`status${id}`)
    a.value = item?.status
    const b: any = document.getElementById(`note${item?.id}`)
    b.value = item?.note
    const c: any = document.getElementById(`note_check${id}`)
    c.checked = false
    setTriggerOne(!triggerOne)
  }

  const [folioCheck, setFolioCheck] = useState(false)

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Edit Options</div>
      </div>

      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-8'>
          <label className='form-label fw-bold'>Status:</label>
          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              name='status'
              id={`status${item?.id}`}
              defaultValue={item?.status}
            >
              <option></option>
              <option value='submitted'>Submitted</option>
              <option value='in_process'>In Process</option>
              <option value='voide'>Voide</option>
              {/*  <!-- <option value='approved'>Approved</option>
              <option value='rejected'>Rejected</option>*/}
            </select>
          </div>
        </div>

        {/* <div className='mb-8'>
          <label className='form-label fw-bold'>Edit Portfolio:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              name='note_check'
              id={`note_check${item?.id}`}
              defaultChecked={false}
              onChange={() => setFolioCheck(!folioCheck)}
            />
            <label className='form-check-label'>Give Reason?</label>
          </div>
        </div> */}

        {folioCheck === true ? (
          <>
            <div className='mb-8'>
              <label className='form-label fw-bold'>Reason:</label>

              <div className='d-flex'>
                <input
                  name='note'
                  id={`note${item?.id}`}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter note'
                />
              </div>
            </div>
            {/* <div className='mb-8'>
              <label className='form-label fw-bold'>Transaction Number:</label>

              <div className='d-flex'>
                <input
                  name='transactionId'
                  id={`transactionId${item?.transactionId }`}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter Transaction Number'
                />
              </div>
            </div>*/}
          </>
        ) : (
          ``
        )}
        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={() => resetData(item?.id)}
          >
            Reset
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={() => applyData(item?.id)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
