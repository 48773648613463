import {access} from 'fs'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem('x-access-token')
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  const auth = getAuth()
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {'x-access-token': string}}) => {
      if (auth && auth.data.token) {
        // config.headers//  Authorization = `Bearer ${auth.data.token}`
        config.headers['x-access-token'] = auth.data.token
        axios.defaults.headers.common = {Authorization: `Bearer ${auth.data.token}`}
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  // FIXME: this is not allowing to login
  // axios.interceptors.response.use(
  //   (res: any) => {
  //     // redirect to login page if session is expired and clear local storage
  //     if (res.data.sessionExpired) {
  //       localStorage.clear()
  //     }

  //     return res
  //   },
  //   (err: any) => Promise.reject(err)
  // )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
