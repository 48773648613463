import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    // remove all the tokens from local storage
    localStorage.clear()
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (auth: AuthModel) => {
      try {
        if (!didRequest.current) {
          const data = await getUserByToken(auth)
          if (data) {
            setCurrentUser(data)
            localStorage.setItem('x-access-token', data.token)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.data.token) {
      // console.log(auth.data.token)
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let logoutTimeout: any = null
    let warningTimeout: any = null

    function resetTimeout() {
      clearTimeout(logoutTimeout)
      clearTimeout(warningTimeout)

      warningTimeout = setTimeout(() => {
        // Warn user 10 seconds before logout
        // TODO: show warning
      }, 3 * 60 * 1000 - 10 * 1000) // 2 minutes and 50 seconds in milliseconds

      logoutTimeout = setTimeout(() => {
        logout()
        console.log('3 minutes of inactivity detected')
      }, 3 * 60 * 1000) // 3 minutes in milliseconds
    }

    if (process.env.REACT_APP_ENV !== 'development') {
      // only session out in production
      window.addEventListener('mousemove', resetTimeout)
      window.addEventListener('keypress', resetTimeout)

      resetTimeout()
    }

    return () => {
      window.removeEventListener('mousemove', resetTimeout)
      window.removeEventListener('keypress', resetTimeout)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
