import * as Yup from 'yup'

export interface ICreateAccount {
  customerSelect: string
  amc: string
  scheme: string
  folioStatus: string
  asOnDate: string
}

const createAccountSchemas = [
  Yup.object({
    customerSelect: Yup.string()
      .required('Please select a user to proceed')
      .label('Customer Select')
      .notOneOf(['', null, undefined]),
  }),
  Yup.object().shape({
    amc: Yup.string().label('Display SIP'),
    scheme: Yup.string().label('Display SIP'),
    folioStatus: Yup.string().label('Folio Status'),
    asOnDate: Yup.string().label('As on Date'),
  }),
]

const inits: ICreateAccount = {
  customerSelect: '',
  amc: '',
  scheme: '',
  folioStatus: 'all',
  asOnDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
    .toISOString()
    .split('T')[0],
}

export {createAccountSchemas, inits}
