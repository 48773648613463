import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../user-management/users-list/core/_models'
import {getAuth} from '../../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../auth/core/Auth'
import {ToastifyWidget} from '../../../../../_metronic/partials/widgets'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/aum_report`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {userReport, customerId, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const [userReportData, setUserReportData] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()
  //console.log(state)

  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  // const temp = userReport.reduce((a: any, {schemeName, ...arr}: any) => {
  //   a[schemeName] = a[schemeName] || {schemeName, arr: new Set()}
  //   a[schemeName].arr.add(arr)
  //   return a
  // }, {})
  // const finalUserReport = Object.values(temp).map((d: any) => ({
  //   schemeName: d.schemeName,
  //   arr: [...d.arr],
  // }))

  const getReport = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}?${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (!auth) {
      window.location.reload()
    }
    getReport().then((d: any) => {
      // console.log('dsfdsfdsf',d.data)
      setUserReportData(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          window.location.reload()
        }
      }
    })
  }, [])

  return (
    <div className=''>
      {/* back button  */}
      <div className='d-flex justify-content-start mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-customer-report', {
                  state: {
                    customerId,
                    filters,
                  },
                })
              : navigate('/reports/current-holdings', {
                  state: {
                    customerId,
                    filters,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
        <button
          className='btn btn-light'
          onClick={() => {
            // create a letterhead for print with logo and address
            const letterHead = `
              <div class='container-fluid mb-1 bg-white p-2'>
                <div class='row text-center d-flex align-items-center'>
                  <div class='col-md-3 d-flex ms-0 ps-0'>
                    <img src='/media/logos/MF-sewa2.png' alt='MF-Sewa Logo' style="height:75px"/>
                  </div>
                  <div class='col-md-9'>
                    <div class='fs-8'>MF Sewa Kendra</div>
                    <div class='fs-8'>
                      Head Office: MF Sewa, Near Veterinary Hospital, Morinda, Punjab
                    </div>
                    <div class='fs-8'>
                      Our Corporate Office: SCO 333-334, 1st Floor, Cabin No. 208-221, Megabyte Business
                      Centre, Sector 35-B, Chandigarh - 160022
                    </div>
                  </div>
                </div>
              </div>
            `
            const filename = `${allCustomerDetails?.applicantGroupName} - ${new Date(
              filters.asOnDate
            ).toLocaleDateString('en-GB')}`
            const printContents = document.getElementById('printArea')?.innerHTML
            document.body.innerHTML = letterHead + printContents

            document.title = filename
            window.print()
            window.location.reload()
          }}
        >
          Print
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 ps-2' />
        </button>
      </div>

      <div id='printArea'>
        <div className='card card-custom mb-3'>
          <div className='card-header bg-secondary'>
            <div className='card-title'>
              <h3 className='card-label'>AUM Report - {filters.report} Wise</h3>
            </div>
          </div>
          {/* <div className='card-body bg-light d-flex row py-3'>
          <div className='col-md-6 text-start'>
            Client Name : {allCustomerDetails?.applicantGroupName || '-'} (
            <span className='text-primary'>
              <u>{allCustomerDetails?.ucc || '-'}</u>
            </span>
            )
          </div>
        </div> */}
        </div>

        <div className='px-2' style={{fontSize: '10px'}}>
          <div className='overflow-scroll'>
            {/* <table className='table table-hover table-sm position-relative'>
              <thead className='table-info'>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top'>Sr. No</th>
                  <th className='text-center align-middle sticky-top'>Group</th>
                  <th className='text-center align-middle sticky-top'>Kfintech</th>
                  <th className='text-center align-middle sticky-top'>Direct</th>
                  <th className='text-center align-middle sticky-top'>Cams</th>
                  <th className='text-center align-middle sticky-top'>Amount</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table> */}
            {userReportData && userReportData === undefined ? (
              <tr>
                <td colSpan={keys.length + 2} className='text-center'>
                  No data found
                </td>
              </tr>
            ) : (
              <>
                {console.log(userReportData)}
                {/* //   {userReportData && */}
                {/* //     userReportData.length && */}
                {/* //     userReportData.map((d: any, i: number) => <></>)} */}
              </>
            )}
            {userReportData && userReportData !== undefined ? (
              <>
                <table
                  className='table table-sm position-relative'
                  dangerouslySetInnerHTML={{__html: userReportData}}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const AUMReport = () => (
  <Routes>
    <Route element={<Report />}>
      <Route path='users/generate-aum-report' element={<Report />} />
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {AUMReport}
